/* chat pop up css */

.open-new-chat-list.people-list {
    width: 100%;
    margin-top: 20px;
}

#newchat .search-container button .search-icon {
    left: 13px;
}

.people-list.open-new-chat-list ul li .time {
    right: 50px;
}

.open-new-chat-list span.close-icon {
    position: absolute;
    right: 0;
    font-size: 18px;
    color: #CECECE;
    top: 16px;
    cursor: pointer;
}

.people-list ul li.notification .close-icon {
    color: #4c4c4c;
}

.create-group-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
}

.icon.sprite-icon1.crate-group-icon {
    width: 20px;
    height: 22px;
    top: 4px;
    display: inline-block;
    cursor: pointer;
    background-size: 600px;
    background-position: -120px -260px;
}

#newchat hr.seperator {
    border-top: 1px dashed #979797;
    margin-top: 12px;
    margin-bottom: 20px;
}

#newchat .search-chip {
    margin-bottom: 8px;
}

/* end of chat pop up css */

p {
    margin-bottom: 12px;
}

h1 {
    font-size: 30px;
    line-height: 35px;
    color: #4C4C4C;
    font-weight: normal;
    margin-bottom: 0;
}

ul li {
    list-style: none;
}

.icon-accordion {
    display: none;
}

/* padding define */

.pad-rt-12 {
    padding-right: 12px;
}

.pad-lt-12 {
    padding-left: 12px;
}

.pad-rt-15 {
    padding-right: 15px;
}

.pad-lt-15 {
    padding-left: 15px;
}

.pad-lt-0 {
    padding-left: 0;
}

.pad-rt-0 {
    padding-right: 0;
}

.pad-rt-20 {
    padding-right: 20px;
}

.pad-rt-24 {
    padding-right: 24px;
}

.pad-lt-20 {
    padding-left: 20px;
}

.pad-rt-10 {
    padding-right: 10px;
}

.pad-lt-10 {
    padding-left: 10px;
}

.pad-0 {
    padding: 0;
}

.mr-bt-30 {
    margin-bottom: 30px;
}

.mr-bt-20 {
    margin-bottom: 20px;
}

.mr-lt-20 {
    margin-left: 8%;
}

.mr-top-14 {
    margin-top: 14px;
}

.mr-top-0 {
    margin-top: 0;
}

hr.seperator.mr-top-0 {
    margin-top: 0;
}

.mr-top-24 {
    margin-top: 24px;
}

.mr-top-8 {
    margin-top: 8px;
}

.mr-top-16 {
    margin-top: 16px;
}

.mr-bt-16 {
    margin-bottom: 16px;
}

.mr-top-30 {
    margin-top: 30px;
}

.mr-bt-24 {
    margin-bottom: 24px;
}

.mr-lt-30 {
    margin-left: 30px;
}

.mr-rt-30 {
    margin-right: 30px;
}

.mr-rt-20 {
    margin-right: 20px;
}

.mr-lt-20 {
    margin-left: 8%;
}

.mr-bt-10 {
    margin-bottom: 10px;
}

.posi-relative {
    position: relative;
}

.custom-select.min-176 {
    min-width: 176px;
}

/* .table-fixed > thead {
    width: 100%;
  }
  .table-fixed > tbody {
    height: 230px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed > thead, .table-fixed > tbody, .table-fixed > tr, .table-fixed > td, .table-fixed > th {
    display: block;
  }
 
  .table-fixed tbody td, .table-fixed thead > tr> th {
    float: left;
    border-bottom-width: 0;
  } */

/* .table-fixed { overflow-y: auto; height: 250px; }
.table-fixed table > thead th { position: sticky; top: 0; background: #B71B1B !important; } */

/* .table-fixed {
    width: 100%;
  }
  .table-fixed > tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
  }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
  }
  .table-fixed tbody td {
    float: left;
  }
  .table-fixed thead tr th {
    float: left;
    background-color: #f39c12;
    border-color: #e67e22;
  } */

.mr-rt-20 {
    margin-right: 20px;
}

.btn-type-right {
    text-align: right;
}

.float-rt {
    float: right;
}

.text-rt {
    text-align: right;
}

.dispaly-none {
    display: none;
}

/* end of padding define */

/* body dopshaow */

body.drop-shadow {
    position: relative;
    overflow: hidden;
}

body.drop-shadow:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .45);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

/* button css */

.btn {
    min-width: 154px;
    min-height: 40px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    background: #fff;
    font-weight: 500;
    padding: 6px 18px;
}

.btn:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.btn_table {
    min-height: 48px;
    height: 48px;
}

.btn_success {
    border: 1.5px solid #6AA14E;
    color: #6AA14E;
}

.btn_success:hover {
    border: 1.5px solid #6AA14E;
    color: #ffffff;
    background: #6AA14E;
}

.btn_danger {
    border: 1.5px solid #B71B1B;
    color: #B71B1B;
}

.btn_danger:hover {
    border: 1.5px solid #B71B1B;
    color: #ffffff;
    background: #B71B1B;
}

.btn_dark {
    border: 1.5px solid #282C37;
    color: #282C37;
}

.btn_dark:hover {
    border: 1.5px solid #282C37;
    color: #fff;
    background: #282C37;
}

.btn_primary {
    border: 1.5px solid #4DBCE9;
    color: #4DBCE9;
}

.btn_primary:hover {
    border: 1.5px solid #4DBCE9;
    color: #fff;
    background: #4DBCE9;
}

.btn_checkout {
    border: 1.5px solid #6AA14E;
    color: #6AA14E;
}

.btn_checkout:hover {
    border: 1.5px solid #6AA14E;
    color: #fff;
    background: #6AA14E;
}

.btn-icon-wrap .icon {
    position: relative;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-position: -61px -522px;
    margin-right: 10px;
    top: 2px;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.btn-icon-wrap .icon.upload-icon {
    background-position: -97px -213px;
}

.upload-icon:hover .btn-icon-wrap .icon.upload-icon {
    background-position: -121px -213px;
}

.btn-icon-wrap .icon.checkout {
    background-position: -97px -64px;
    width: 20px;
    height: 20px;
}

.checkout-btn:hover .chat-list-btn .icon.checkout {
    background-position: -127px -64px;
}

.chat-list-btn .icon.individuals {
    background-position: -142px -233px;
    width: 14px;
    height: 20px;
}

.chat-list-btn button.active .icon.individuals {
    background-position: -121px -233px;
}

/* .checkout-btn:hover .chat-list-btn .icon.groupchat{
    background-position: -127px -64px;
} */

.chat-list-btn .icon.groupchat {
    background-position: -146px -258px;
    width: 20px;
    height: 22px;
    top: 4px;
}

.chat-list-btn button.active .icon.groupchat {
    background-position: -120px -258px;
}

.chat-list-btn button, .chat-list-btn button:hover {
    color: #A9AAAE;
    line-height: 30px;
    padding: 0;
}

.chat-list-btn button.btn.active {
    border: 1px solid #B71B1B;
    background-color: #FFFFFF;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    color: #B71B1B;
    padding: 0 12px;
}

/* button.btn.individuals{
    line-height: 30px;
} */

/* .checkout-btn:hover .btn-icon-wrap .icon.individuals{
    background-position: -127px -64px;
} */

.btn.add:hover .btn-icon-wrap .icon {
    background-position: -61px -498px;
}

.block_btn {
    width: 100%;
    display: block;
}

button.clone.btn {
    -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
    background-color: #FFFFFF;
    border-radius: 4px;
    min-height: 30px;
    height: 30px;
    border: 1px solid #6AA14E;
    width: auto;
    min-width: auto;
    font-weight: normal;
    color: #6AA14E;
    padding: 5px 12px;
}

.search-chip {
    border: 1px solid #4DBCE9;
    border-radius: 4px;
    color: #4DBCE9;
    font-size: 14px;
    padding: 0 33px 0 10px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-weight: 500;
    min-width: 113px;
    display: inline-block;
    position: relative;
}

.search-chip span {
    position: absolute;
    width: 15px;
    height: 15px;
    background-position: -76px 446px;
    top: 8px;
    right: 10px;
}

.disabled-btn, .disabled-btn:hover {
    color: #E6E6E6;
    border: 1px solid #e6e6e6;
    background: transparent;
    min-width: 188px;
    cursor: not-allowed;
}

button.btn.mute-btn {
    border: 1.5px solid #292C36;
    max-width: 123px;
    min-width: 123px;
    background-color: #FFFFFF;
    min-height: 30px;
    padding: 0 15px 0 38px;
    position: relative;
}

button.btn.mute-btn .mute-icon.sprite-icon {
    position: absolute;
    left: 13px;
    background-position: -208px -546px;
    width: 20px;
    height: 20px;
}

.btn-zoom {
    border: 1px solid #4C4C4C;
    height: 60px;
    width: 60px;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
}

.btn-zoom:hover {
    border: 1px solid #4DBCE9;
    background-color: #4DBCE9;
}

/* end of buttom css */

/* bootstrap Modal design css */

#job-link.modal .modal-dialog {
    max-width: 730px;
}

.footer-btn-alg button {
    margin: 0 auto;
}

.modal-footer {
    border-top: none;
    padding: 24px;
    background: #f5f5f5;
    display: block;
}

.modal-title {
    text-transform: uppercase;
}

.modal-body.gry-bg {
    background: #f5f5f5;
}

.modal .modal-dialog {
    max-width: 448px;
}

#master-acc.modal .modal-dialog {
    max-width: 586px;
}

.modal .modal-content {
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
    /* border-radius: 2px; */
    border: none;
    background-color: #FFFFFF;
}

.modal .modal-content .modal-header {
    border-radius: 2px 2px 0 0;
    background-color: #292C36;
    -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    height: 68px;
    border-bottom: 2px solid #B71B1B;
    padding: 24px;
}

.modal .modal-content .modal-header h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.modal .modal-content .modal-body {
    padding: 24px;
}

.modal .modal-content .modal-header button {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position: -30px -418px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    opacity: 1;
}

.modal-backdrop {
    background-color: #000000;
}

.modal-backdrop.show {
    opacity: .45;
}

.modal_button {
    text-align: right;
    margin-top: 16px;
}

.activate-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.activate-btns button {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
}

.activate-btns button:first-child {
    margin-right: 16px;
}

.modal .modal-content .modal-body .modal-text {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    color: #4c4c4c;
}

/* end of bootstrap Modal design css */

/* custom input type file css */

.custom-input-file.height-84 label {
    height: 84px;
}

.custom-input-file label {
    padding: 10px;
    background-color: #F2F2F2;
    display: table;
    color: #fff;
    height: 111px;
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    margin: 0;
    cursor: pointer;
    width: 100%;
}

.custom-input-file label .inner-label {
    display: block;
    height: 100%;
    border: 1px dashed #c4c4c4;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

.custom-input-file label .inner-label .upload-text {
    color: #000000;
    font-size: 14px;
    margin-top: 2px;
}

.custom-input-file input[type="file"] {
    display: none;
}

.custom-input-file label .inner-label .upload-icon {
    width: 24px;
    height: 24px;
    background-position: -58px -420px;
}

.custom-input-file.attach-icon label .inner-label .upload-icon {
    width: 24px;
    height: 24px;
    background-position: -549px -207px;
}

.custom-input-file label .inner-label .upload-icon.down {
    width: 24px;
    height: 24px;
    background-position: -277px -490px;
}

.custom-file-input1 {
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.custom-file-input1 input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    zoom: 1;
    /* Fix for IE7 */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.custom-input-file label .inner-label img {
    width: 40px;
}

.file-uploaded .upload-text, .file-uploaded .upload-icon {
    display: none;
}

.custom-input-file.file-uploaded input[type="file"] {
    display: block;
    opacity: 1;
    position: relative;
    /* width: calc(100% - 100px); */
    /* left: -50px; */
    color: #000;
    margin-left: -45px;
    outline: none;
}

.file-uploaded .wrap-input {
    position: relative;
    /* margin-left: 50px; */
}

.file-uploaded .wrap-input .show-after {
    width: 90px;
    position: absolute;
    height: 28px;
    top: 0;
    left: -45px;
    background: #fff;
    z-index: 999;
}

.custom-input-file.file-uploaded label .inner-label {
    background: #fff;
}

.custom-input-file.file-uploaded, .custom-input-file {
    width: 100%;
}

.custom-input-file.bg-chg.file-uploaded img {
    display: block;
}

.custom-input-file.bg-chg img {
    display: none;
}

.upload-error-text {
    color: #B71B1B;
    font-size: 12px;
    font-weight: 500;
}

.custom-input-file.error-upload label {
    border: 1px solid #B71B1B;
    background: #fff;
}

.custom-input-file.error-upload label .inner-label {
    border: 1px dashed #B71B1B;
}

.custom-input-file.error-upload label .inner-label .upload-icon.down {
    width: 24px;
    height: 24px;
    background-position: -280px -558px;
}

/* end of custom input type file css */

/* date picker css */

.datepicker .datepicker-switch {
    width: 145px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 500;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22), 0 24px 24px 0 rgba(0, 0, 0, 0.3);
    border: none;
}

.datepicker-dropdown th.next, .datepicker-dropdown th.prev {
    color: #fff;
    position: relative;
}

.datepicker-dropdown th.next:before {
    background-size: 600px;
    background-position: -77px 594px;
    content: "";
    width: 10px;
    height: 18px;
    position: absolute;
    display: inline-block;
    ;
}

.datepicker-dropdown th.prev:before {
    background-size: 600px;
    background-position: -76px 593px;
    content: "";
    width: 10px;
    height: 18px;
    position: absolute;
    display: inline-block;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.datepicker thead tr:first-child th:hover {
    background: transparent !important;
}

.datepicker table tr th {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    font-weight: normal;
}

.datepicker table tr td {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
}

.datepicker table tr td, .datepicker table tr th {
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    border: none;
}

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.active.active {
    color: #fff!important;
    background-color: #B71B1B !important;
    border-color: #B71B1B !important;
}

.datepicker table tr td.day:hover, .datepicker table tr td.day.focused {
    background: #B71B1B !important;
    color: #fff!important;
}

.datepicker-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    display: -webkit-flex;
}

.datepicker-wrap input.form-control+label {
    line-height: 19px;
}

.datepicker-wrap input:focus+label {
    color: #E33945;
}

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:focus, .datepicker table tr td span.active:hover:focus, .datepicker table tr td span.active.disabled:focus, .datepicker table tr td span.active.disabled:hover:focus, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td span.active, .open .dropdown-toggle.datepicker table tr td span.active:hover, .open .dropdown-toggle.datepicker table tr td span.active.disabled, .open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
    background-color: #B71B1B !important;
    border-color: #B71B1B!important;
}

.datepicker table tr td span {
    height: 44px !important;
    line-height: 44px !important;
}

.datepicker-wrap.sprite-icon-before:before {
    background-position: -32px -446px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 14px;
    top: 44px;
    pointer-events: none;
}

.datepicker-wrap input {
    cursor: pointer;
}

.react-datepicker{
    margin-left: auto;
    border: solid 1px lightgray;
}
.react-datepicker__header{
    background-color: white;
}

/* end of date picker css */

/* pops over css */

.pops-over {
    border: 1px solid #000000;
    border-radius: 4px;
    background-color: #F4EB49;
    -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    position: absolute;
    margin-left: -183px;
    left: 50%;
    height: 48px;
    min-width: 345px;
}

.pops-over p {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 0;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;
    line-height: 16px;
}

.pops-over p:before {
    content: "";
    width: 20px;
    height: 20px;
    background-position: -360px 30px;
    position: absolute;
    top: -2px;
    left: 0;
}

/* custom country dropdown */

.country {
    position: relative;
    margin: 0 auto;
    width: 73px;
    /* display: inline-block; */
}

.country ul {
    padding-left: 0;
    margin-bottom: 0;
}

.country .select {
    position: relative;
    padding: 0;
    height: 18px;
    line-height: 18px;
    border: none;
    background: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.country .select .flagstrap-icon {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    margin-right: 10px;
    width: 34px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: -15px 57px;
}

#country-drop .flagstrap-icon {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: inline-block;
    margin-right: 10px;
    width: 34px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: 600px;
    background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-ad {
    background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-ae {
    background-position: -15px 57px;
}

.country .select .flagstrap-icon.flagstrap-af {
    background-position: -15px 57px;
}

.country .dropdown .flagstrap-icon {
    vertical-align: middle;
}

.country .dropdown li {
    padding: 6px 15px;
    line-height: 34px;
    font-size: 13px;
    font-weight: 400;
    color: #828282;
    cursor: pointer;
    border-bottom: 1px solid #CBD1D5;
}

.country .dropdown li:last-child {
    border-bottom: none;
}

.country .dropdown li:first-child {
    margin-top: 0px;
}

.country .dropdown li:last-child {
    margin-bottom: 0px;
}

.country .dropdown li:hover {
    background: rgba(196, 196, 196, .4);
    color: #4a4a4a;
    font-weight: 500;
}

.country .dropdown li.open {
    display: block;
}

.country .dropdown {
    display: none;
    position: absolute;
    top: 39px;
    left: auto;
    width: 100%;
    right: 0;
    background: #fff;
    z-index: 1;
    min-width: 177px;
    border: none;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    border-radius: 4px;
}

.country .dropdown:before {
    content: "";
    width: 26px;
    height: 15px;
    position: absolute;
    right: 18px;
    top: -15px;
    margin-left: -7px;
}

.country ul li, .country li {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    color: #4c4c4c;
}

#country {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    position: relative;
}

#country:before {
    content: "";
    width: 12px;
    height: 12px;
    background-size: 600px;
    background-position: -78px 570px;
    position: absolute;
    top: 5px;
    right: -3px;
    transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
}

#country.open:before {
    background-position: -98px 571px;
}

/* end of custom contry dropdown */

/* status select box */

.status-select .select-selected {
    border: none;
    background: transparent;
    font-size: 14px;
    color: #41BA1A;
    height: 60px;
    position: relative;
    top: -5px;
    line-height: 44px;
}

.status-select .custom-select {
    background: transparent;
}

.status-select .select-selected:after {
    position: absolute;
    content: "";
    top: 26px;
    right: calc(50% - 42px);
    width: 11px;
    height: 8px;
    background-size: 600px;
    background-position: -77px 570px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.bug-ticket-table .status-select .select-selected:after {
    /* this might need to remive */
    right: calc(50% - 58px);
}

.status-select .select-selected.select-arrow-active:after {
    position: absolute;
    content: "";
    top: 26px;
    right: calc(50% - 42px);
    width: 12px;
    height: 8px;
    background-size: 600px;
    background-position: -97px 570px;
}

/* .status-select .select-items .selecthover{
    height: 60px;
    line-height: 42px;
    color: #41BA1A;
} */

.status-select .select-items {
    top: calc(100% + 6px);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.status-select .select-selected.select-arrow-active {
    color: #41BA1A;
    background-color: transparent;
    border: 1px solid #CBD1D5;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.status-select .select-items .selecthover:hover {
    background: #f2f2f2;
    color: #B71B1B;
}

/* end of status select box */

/* status report select box */

.custom-select.status-report-select {
    width: 125px;
}

/* end of status report select box */

/* card css */

.card {
    border: 1px solid #C4C4C4;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    background-color: #FFFFFF;
}

.card .card-header p, .card .card-header2 p {
    margin: 0;
}

.card .card-header, .card .card-header2 {
    border-radius: 4px 4px 0 0;
    background-color: #B71B1B;
    /* box-shadow: 0 0 7px 3px rgba(227,57,69,0.27); */
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding-left: 30px;
}

#accordion .card .card-header a {
    color: #4c4c4c;
    display: block;
    width: calc(100% + 30px);
    height: 60px;
    line-height: 60px;
    margin-left: -30px;
    padding-left: 49px;
    font-size: 14px;
}

#accordion .card .card-header {
    padding-right: 0;
    border: none;
}

#accordion .card .card-header2 a {
    color: #4c4c4c;
    display: block;
    width: calc(100% + 30px);
    height: 60px;
    line-height: 60px;
    margin-left: -30px;
    padding-left: 49px;
    font-size: 14px;
}

#accordion .card .card-header2 {
    padding-right: 0;
    border: none;
}

.card .card-body {
    padding: 30px 30px 0;
}

.accordian2 .card .card-body {
    padding: 15px 10px 0;
}

#accordion .card {
    margin-bottom: 15px;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    overflow: hidden;
}

#accordion .card .card-header a.card-link.collapsed, #accordion .card .card-header2 a.card-link.collapsed {
    background: #FBFBFB;
    color: #4c4c4c;
}

#accordion .card .card-header a.card-link, #accordion .card .card-header2 a.card-link {
    background: #B71B1B;
    color: #fff;
}

a.collapsed.card-link.sprite-icon-before:before {
    content: "";
    width: 10px;
    height: 14px;
    position: absolute;
    background-position: -77px -9px;
    top: 23px;
    left: 30px;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

a.card-link.sprite-icon-before:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background-position: -94px -9px;
    top: 23px;
    left: 30px;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

/* accordian 2 css */

/* #accordion.accordion2 .card .card-header a.card-link {
    background: #fff;
    color: #B71B1B;
    border-bottom: 1px dashed #979797;
} */

#accordion.accordian2 .card {
    border: none;
}

#accordion.accordian2 .card .card-header+div {
    border: 2px solid #B71B1B;
    border-top: 0px;
    position: relative;
}

#accordion.accordian2 .card .card-header2+div {
    border: 2px solid #B71B1B;
    border-top: 0px;
    position: relative;
}

#accordion.accordian2 .card>.card-header>a.card-link {
    background: #fff;
    color: #B71B1B;
    border: 2px solid #B71B1B;
    border-bottom: none;
}

#accordion.accordian2 .card>.card-header2>a.card-link {
    background: #fff;
    color: #B71B1B;
    border: 2px solid #B71B1B;
    border-bottom: none;
}

#accordion.accordian2 .card>.card-header>a.card-link.collapsed {
    border: 1px solid #4C4C4C;
    border-radius: 4px;
    color: #4c4c4c;
}

#accordion.accordian2 .card>.card-header2>a.card-link.collapsed {
    border: 1px solid #4C4C4C;
    border-radius: 4px;
    color: #4c4c4c;
}

#accordion.accordian2 .card .card-header+div:before {
    content: "";
    width: calc(100% - 16px);
    height: 1px;
    border-bottom: 1px dashed #979797;
    position: absolute;
    left: 8px;
}

.accordian2 .card .card-header {
    height: 48px;
}

#accordion.accordian2 .card .card-header a {
    height: 48px;
    line-height: 48px;
}

#accordion.accordian2 .card .card-header2+div:before {
    content: "";
    width: calc(100% - 16px);
    height: 1px;
    border-bottom: 1px dashed #979797;
    position: absolute;
    left: 8px;
}

.accordian2 .card .card-header2 {
    height: 48px;
}

#accordion.accordian2 .card .card-header2 a {
    height: 48px;
    line-height: 48px;
}

.accordian2 a.collapsed.card-link.sprite-icon-before:before {
    top: 16px;
}

.accordian2 a.collapsed.card-link.sprite-icon-before:before {
    content: "";
    width: 16px;
    height: 14px;
    position: absolute;
    background-position: -98px -88px;
    top: 16px;
    left: 20px;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}

.accordian2 a.card-link.sprite-icon-before:before {
    content: "";
    width: 14px;
    height: 14px;
    position: absolute;
    background-position: -78px -84px;
    top: 20px;
    left: 20px;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* video panel css */

.video-section:hover {
    -webkit-box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.13);
            box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.13);
}

.video-section {
    border: 1px solid #CACACA;
}

.video-section .video-play-area {
    min-height: 154px;
}

.video-section .video-play-area iframe {
    max-width: 100%;
}

.video-section .video-description {
    height: 48px;
    padding: 12px;
    line-height: 28px;
    font-size: 16px;
    color: #4c4c4c;
    font-weight: 500
}

.custom-input-file.manage-ht label {
    min-height: 204px;
}

/* end of video panel css */

/* card slider img */

.slick-slide img {
    width: 100%;
}

.slick-slide {
    margin: 0 15px !important;
    overflow: hidden;
}

/* the parent */

.slick-list {
    margin: 0 -15px !important;
    ;
}

.slick-slider button {
    text-indent: -99999px;
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    z-index: 1;
    outline: none;
}

.slick-slider button.slick-prev {
    top: 50%;
    position: absolute;
    margin-top: -10px;
    left: -6px;
    background-size: 600px;
    background-position: -59px -454px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slick-slider button.slick-next {
    top: 50%;
    position: absolute;
    margin-top: -10px;
    right: -6px;
    background-size: 600px;
    background-position: -59px -454px;
}

/* end of card slider img */

/* custom simple drop down */

.custom-drop-option button.dropdown-toggle, .custom-drop-option button.dropdown-toggle:hover, .custom-drop-option button.dropdown-toggle:focus {
    border: 1px solid #C4C4C4;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    border-radius: 4px;
    padding: 0 33px 0 16px;
    width: auto;
    color: #4C4C4C;
    font-weight: 500;
    height: 40px;
    line-height: 16px;
    background: #fff;
    outline: none;
}

.custom-drop-option .btn-primary:not(:disabled):not(.disabled).active, .custom-drop-option .btn-primary:not(:disabled):not(.disabled):active, .custom-drop-option.show>.btn-primary.dropdown-toggle {
    color: #4C4C4C;
    background: #fff;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    border: 1px solid #C4C4C4;
}

.custom-drop-option .dropdown-toggle::after {
    display: none;
}

.custom-drop-option {
    position: relative;
    display: inline-block;
}

/* .custom-drop-option button.dropdown-toggle{
    width: 140px;
} */

.custom-drop-option:before {
    content: "";
    width: 12px;
    height: 12px;
    background-size: 600px;
    background-position: -78px 570px;
    position: absolute;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    right: 16px;
    top: 15px;
    pointer-events: none;
}

.custom-drop-option.show:before {
    background-position: -98px 571px;
}

.custom-drop-option .dropdown-menu.show {
    display: block;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0px;
    margin-top: 5px;
    /* min-width: 140px; */
}

.custom-drop-option .dropdown-menu.show li {
    text-align: center;
    border-bottom: 1px solid #EFEFEF;
}

.custom-drop-option .dropdown-menu.show li:hover {
    background: rgba(196, 196, 196, .4);
}

.custom-drop-option .dropdown-menu.show li:hover a {
    /* font-size: 16px; */
    font-weight: 500;
}

.custom-drop-option .dropdown-menu.show li a {
    color: #828282;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    display: block;
    padding: 9px 8px;
}

/* chip css */

.custom-chip {
    background: #dddddd;
    -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    height: 40px;
    color: #4c4c4c;
    font-size: 14px;
    line-height: 16px;
    min-width: 92px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    padding: 0 12px;
    cursor: pointer;
}

.custom-chip span {
    background-position: -71px -132px;
    display: inline-block;
    width: 18px;
    height: 20px;
    cursor: pointer
}

/* end of chip css */

/* custom checkbox */

label.cust-checkbox.with-bg {
    width: 90px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #C4C4C4;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    padding-left: 40px;
    font-size: 18px;
    color: #4c4c4c;
}

.cust-checkbox.with-bg input:checked~.checkmark {
    top: 12px;
    left: 12px;
    background-position: -170px -572px;
}

.cust-checkbox.with-bg.bg-filled {
    background-color: #B71B1B;
    color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid #B71B1B;
}

.cust-checkbox.with-bg .checkmark {
    top: 12px;
    left: 12px;
}

.cust-checkbox.white-check {
    color: #ffffff;
}

.cust-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 14px;
    color: #828282;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
}

.custom-select2 .cust-checkbox {
    display: block;
}

/* Hide the browser's default checkbox */

.cust-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.cust-checkbox .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background-size: 600px;
    background-position: -76px -107px;
}

.cust-checkbox.white-check .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background-size: 600px;
    background-position: -140px -573px;
}

.cust-checkbox.white-check input:checked~.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background-size: 600px;
    background-position: -169px -574px;
}

/* When the checkbox is checked, add a blue background */

.cust-checkbox input:checked~.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background-size: 600px;
    background-position: -105px -108px;
}

/* Create the checkmark/indicator (hidden when not checked) */

.cust-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.cust-checkbox input:checked~.checkmark:after {
    display: block;
}

/* end of custom checkbox */

/* custom radio button */

.cust-radio {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    cursor: pointer;
    color: #828282;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */

.cust-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */

.cust-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #cacaca;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */

.cust-radio input:checked~.checkmark {
    background-color: #fff;
    border: 2px solid #B71B1B;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.cust-radio .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.cust-radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.cust-radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #B71B1B;
}

/* end of custom radio */

/* filter dropdown css */

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

.filter-dropdown .dropdown-menu {
    min-width: 270px;
    padding: 0;
    border: none;
    position: relative;
    left: auto !important;
    right: 0;
    -webkit-transform: none !important;
            transform: none !important;
}

.filter-dropdown>.dropdown-menu {
    margin-top: 60px;
    -webkit-box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
            box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
}

.filter-dropdown>.dropdown-menu:before {
    content: "";
    width: 32px;
    height: 13px;
    position: absolute;
    top: -12px;
    right: 16px;
}

.dropdown.filter-dropdown {
    display: inline-block;
}

.filter-dropdown ul.dropdown-menu.show>li:last-child {
    border-bottom: none;
}

.filter-dropdown ul.dropdown-menu.show>li:last-child ul {
    border-top: 1px solid #979797;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.filter-dropdown .apply-filter {
    min-height: 48px;
    background: #B71B1B;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    padding: 15px 12px 15px 15px;
}

.filter-dropdown .apply-filter span {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.filter-dropdown .apply-filter span.apply {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.filter-dropdown .apply-filter.customFilter {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-justify-content: flex-end;
}

.filter-dropdown .apply-filter.customFilter span.apply {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #ffffff;
    border-radius: 4px;
    padding: 4px 12px;
}

.filter-dropdown .cust-checkbox .checkmark {
    top: 15px;
    left: 14px;
}

.filter-dropdown .cust-checkbox input:checked~.checkmark {
    top: 15px;
    left: 14px;
}

button.dropdown-toggle, button.dropdown-toggle:hover {
    height: 40px;
    width: 84px;
    min-width: 84px;
    border: 1px solid #B71B1B;
    border-radius: 4px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
    color: #B71B1B;
    font-size: 14px;
    line-height: 15px;
    padding-left: 28px;
    padding-top: 9px;
}

.filter-dropdown .dropdown-submenu .dropdown-menu {
    /* position: relative; */
    top: 0px;
    left: 0;
    border: none;
    margin-top: 2px;
    /* float: none; */
}

.filter-dropdown .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    width: 17px;
    height: 18px;
    background-size: 600px;
    background-position: -64px 208px;
    position: absolute;
    left: 11px;
    top: 11px;
}

.filter-dropdown ul.dropdown-menu.show li {
    /* height: 49px; */
    border-bottom: 1px solid #979797;
    padding: 0px;
    font-size: 14px;
}

.filter-dropdown ul.dropdown-menu.show li a {
    color: #4C4C4C;
    text-decoration: none;
    display: inline-block;
    height: 48px;
    padding-left: 64px;
    padding-top: 16px;
    width: 100%;
    position: relative;
}

.filter-dropdown ul.dropdown-menu.show li label {
    display: block;
    height: 48px;
    padding-top: 15px;
    padding-left: 64px;
}

.filter-dropdown .cust-checkbox.checked {
    background: #f2f2f2;
    color: #4c4c4c;
}

.filter-dropdown .dropdown-submenu .test {
    position: relative;
}

.filter-dropdown .dropdown-submenu .test:before {
    content: "";
    background-size: 600px;
    background-position: -99px 510px;
    position: absolute;
    width: 15px;
    height: 10px;
    top: 20px;
    left: 20px;
}

.filter-dropdown .dropdown-submenu .test.menu-drop:before {
    background-position: -77px 510px;
}

/* End of filter dropdown css */

/* custom tabel design css */

.global-table .table thead tr th, .global-table .table thead tr th:first-child, .global-table .table thead tr th:nth-child(2), .global-table .table thead tr th:nth-child(3), .global-table .table thead tr th:nth-child(4), .global-table .table thead tr th:nth-child(5) {
    width: 20%;
}

.global-table .table tbody tr td table tbody tr td {
    width: 20%;
}

.global-table table table tbody tr td {
    height: 60px;
    vertical-align: middle;
    font-size: 14px;
    color: #4c4c4c;
    font-weight: normal;
}

.global-table.analytic-pop-table table table tbody tr td:first-child {
    font-weight: 400;
}

/* end of custom tabel design css */

/* input form css */

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(76, 76, 76, .6);
    font-size: 16px;
    letter-spacing: 0.75px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(76, 76, 76, .6);
    ;
    font-size: 16px;
    letter-spacing: 0.75px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(76, 76, 76, .6);
    ;
    font-size: 16px;
    letter-spacing: 0.75px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(76, 76, 76, .6);
    ;
    font-size: 16px;
    letter-spacing: 0.75px;
}

input.form-control {
    height: 48px;
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    background-color: #F2F2F2;
    padding-left: 15px;
    padding-right: 15px;
    color: #4C4C4C;
    font-size: 16px;
    margin-bottom: 8px;
}

textarea.form-control {
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    background-color: #F2F2F2;
    padding-left: 15px;
    padding-right: 15px;
    color: #4C4C4C;
    font-size: 16px;
    margin-bottom: 8px;
    resize: none;
}

input.form-control:focus, input.form-control:active, textarea.form-control:focus, textarea.form-control:active {
    -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
            box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
    border: 1px solid #E33945;
}

input.form-control+label, textarea.form-control+label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

input.form-control:focus+label, textarea.form-control:focus+label {
    color: #E33945;
}

.dis-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.f-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
}

input.input-state-tax {
    border: 1px solid #C4C4C4;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    height: 40px;
    border-radius: 4px;
    outline: none;
    text-align: center;
    /* width: 72px; */
    width: 48px;
}

/* end of input box css */

/* select box css */

.custom-select, .custom-select2 {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: 0;
    /* font-size: 1rem; */
    font-weight: normal;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #f2f2f2;
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select select, .custom-select2 select {
    display: none;
    /*hide original SELECT element:*/
}

.select-selected {
    background-color: #f2f2f2;
    border-radius: 4px;
    line-height: 32px;
}

/*style the arrow inside the select element:*/

.select-selected:after {
    position: absolute;
    content: "";
    top: 19px;
    right: 18px;
    width: 17px;
    height: 13px;
    background-size: 600px;
    background-position: -77px 556px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.select-selected.select-arrow-active:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 18px;
    width: 17px;
    height: 13px;
    background-size: 600px;
    background-position: -97px 556px;
}

.select-selected.select-arrow-active {
    color: rgba(76, 76, 76, .3);
    background-color: #ffffff;
    border: 1px solid #E33945;
    -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
            box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
}

/*point the arrow upwards when the select box is open (active):*/

/* .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  } */

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: none;
            box-shadow: none;
}

/*style the items (options), including the selected item:*/

.select-items div:last-child {
    border-bottom: none;
}

.select-selected {
    color: rgba(76, 76, 76, .6);
    padding: 8px 16px;
    border: 1px solid #CBD1D5;
    /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;  */
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    height: 48px;
}

.year-selection .select-selected {
    color: #4c4c4c;
    font-weight: 500;
    line-height: 34px;
}

/*style items (options):*/

.select-items {
    position: absolute;
    background-color: #ffffff;
    top: calc(100% + 9px);
    left: 0;
    right: 0;
    max-height: 291px;
    z-index: 99;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid #CBD1D5;
}

.report-inner-part .select-items {
    max-height: inherit;
    margin-bottom: 16px;
}

/*hide the items when the select box is closed:*/

.select-hide {
    display: none;
}

.select-items .selecthover:hover {
    background: rgba(255, 183, 188, .2);
    font-weight: 500;
    color: #4c4c4c;
}

.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.label-color+label {
    color: #E33945;
}

/* end of select box css */

/* select 2 css override */

.select2-container--open .select2-dropdown--below {
    border-top-left-radius: 4px!important;
    border-top-right-radius: 4px !important;
}

.select2-container--default .select2-results__options .select2-results__option {
    color: #828282;
    padding: 8px 16px;
    cursor: pointer;
    height: 36px;
    border-bottom: 1px solid #CBD1D5;
}

.select2-search--dropdown {
    border-bottom: 1px solid #CBD1D5;
    position: relative;
}

.select2-search--dropdown:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    top: 12px;
    right: 36px;
    background-size: 600px;
    background-position: -98px -137px;
}

.select2-container--default .select2-results__options .select2-results__option:last-child {
    border-bottom: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: rgba(255, 183, 188, .2) !important;
    font-weight: 500;
    color: #4c4c4c !important;
}

.select2-container--default .select2-results__options .select2-results__option[aria-selected=true] {
    background: transparent;
}

.select2-container--default .select2-results>.select2-results__options {
    /* max-height: 200px !important; */
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 48px !important;
    outline: none;
}

.select2-container .select2-selection--single {
    height: 48px !important;
    outline: none;
}

.select2-container--default.select2-container--open .select2-selection--single {
    color: rgba(76, 76, 76, .3);
    background-color: #ffffff;
    border: 1px solid #E33945;
    -webkit-box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
            box-shadow: 0 0 7px 0 rgba(227, 57, 69, 0.36);
    border-radius: 4px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: transparent !important;
    border-style: solid;
    border-width: 0 !important;
    left: auto !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: absolute;
    top: 18px !important;
    right: 18px !important;
    width: 17px !important;
    height: 13px !important;
    background-size: 600px;
    background-position: -77px 556px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent !important;
    border-style: solid;
    border-width: 0 !important;
    left: auto !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: absolute;
    top: 18px!important;
    right: 18px !important;
    width: 17px !important;
    height: 13px !important;
    background-size: 600px;
    background-position: -97px 556px;
}

.select2-container--open .select2-dropdown {
    top: 12px;
    border: 1px solid #C4C4C4 !important;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #EAEAEA !important;
    border-radius: 4px;
    height: 30px;
    width: calc(100% - 22px);
    outline: none;
    padding: 0 28px 0 10px;
}

/* search placeholder color */

.search-container ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cacaca;
    font-size: 14px;
}

.search-container ::-moz-placeholder {
    /* Firefox 19+ */
    color: #cacaca;
    font-size: 14px;
}

.search-container :-ms-input-placeholder {
    /* IE 10+ */
    color: #cacaca;
    font-size: 14px;
}

.search-container :-moz-placeholder {
    /* Firefox 18- */
    color: #cacaca;
    font-size: 14px;
}

.label-color label {
    color: #E33945;
}

.label-color label span {
    color: #828282;
}

/* end of search placeholder color */

/* Header css */

nav+.container-fluid {
    /* margin-top: 64px; */
}

nav.navbar {
    height: 64px;
    background: #fff;
    border-bottom: 2px solid #B71B1B;
    padding: 0 60px 0 0;
}

.breadcrumes {
    padding-left: 34px;
}

.breadcrumes ul {
    margin: 0;
    padding: 0;
}

.breadcrumes ul li {
    display: inline-block;
}

.breadcrumes ul li.active a {
    font-weight: normal;
}

.breadcrumes ul li.active {
    position: relative;
    padding-right: 10px;
}

.breadcrumes ul li.active:before {
    content: ">";
    width: 12px;
    height: 16px;
    position: absolute;
    right: -5px;
    color: #535252;
}

.breadcrumes ul li a {
    color: #535252;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
}

.right-nav-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-align-items: center;
}

.right-nav-menu.head-width {
    width: calc(100% - 243px);
}

nav.navbar .header-logo {
    width: 243px;
    background: #292C36;
    height: 62px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
}

nav.navbar .header-logo .navbar-brand {
    margin: 0;
}

.profileselector {
    border: 1px solid #D2D2D2;
    border-radius: 23.5px;
    padding: 5px 29px 5px 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    position: relative;
    cursor: pointer;
}

.profileselector .user-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 8px;
}

.profileselector .user-image img {
    max-width: 100%;
}

.profileselector .user-name {
    font-size: 14px;
    color: #4C4C4C;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-align-self: center;
    font-weight: 500;
}

.user-dropdown .profileselector .user-arrow {
    width: 12px;
    height: 13px;
    position: absolute;
    right: 0;
    background-position: -97px -27px;
    top: 16px;
    right: 12px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.user-dropdown.show .profileselector .user-arrow {
    background-position: -77px -27px;
}

.user-dropdown {
    position: relative;
    min-width: 185px;
}

.user-dropdown .dropdown-menu {
    min-width: 177px;
    border: none;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    margin-top: 17px;
    padding: 0;
}

.user-dropdown .dropdown-menu ul {
    padding: 0;
    margin: 0;
}

/* .user-dropdown .dropdown-menu.show{
    position: relative;
} */

.speaker-wrap.user-dropdown {
    min-width: auto;
}

.speaker-wrap.user-dropdown .dropdown-menu {
    left: auto;
    right: 11px;
    min-width: 147px;
    max-width: 147px;
}

.speaker-wrap.user-dropdown .dropdown-menu ul {
    padding-left: 0;
    margin-bottom: 0;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li a {
    padding: 0 15px 0 36px;
    min-height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li {
    border-bottom: 1px solid #EFEFEF;
}

.speaker-wrap.user-dropdown .dropdown-menu ul li:last-child {
    padding: 12px;
}

.user-dropdown .dropdown-menu.show:before {
    content: "";
    width: 26px;
    height: 15px;
    position: absolute;
    right: 18px;
    top: -15px;
    margin-left: -7px;
}

.user-dropdown .dropdown-menu a {
    color: #828282;
    font-size: 14px;
    padding: 12px 15px;
    border-radius: 4px;
    position: relative;
}

.dropdown-menu a.Logout {
    text-align: center;
    padding-left: 38px;
}

.user-dropdown .dropdown-menu a.toggle-drop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
}

.user-dropdown .dropdown-menu a.toggle-drop ul {
    margin-top: 2px;
    padding-right: 12px;
}

.user-dropdown .dropdown-menu>ul>li {
    border-bottom: 1px solid #EFEFEF;
}

.user-dropdown .dropdown-menu>ul>li:last-child {
    border-bottom: none;
}

.user-dropdown .dropdown-menu a:active, .user-dropdown .dropdown-menu a:focus, .user-dropdown .dropdown-menu a:hover {
    background: transparent;
    border-radius: 4px;
    border: none;
    outline: none;
}

.dropdown-menu a.Logout span {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 51px;
    background-position: -9px -524px;
    display: inline-block;
}

.dropdown-menu a.mick span {
    width: 18px;
    height: 20px;
    position: absolute;
    left: 15px;
    background-position: -208px -516px;
    display: inline-block;
    top: 6px;
}

.dropdown-menu a.mute span {
    width: 18px;
    height: 20px;
    position: absolute;
    left: 15px;
    background-position: -232px -516px;
    display: inline-block;
    top: 6px;
}

.dropdown-menu a.mute-all span {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 51px;
    background-position: -9px -524px;
    display: inline-block;
}

.cart-item.sprite-icon {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-position: -151px -459px;
    cursor: pointer;
    position: relative;
}
.cart-item.chat-icon-v2 {
    width: 47px;
    height: 46px;
    border-radius: 50%;
    background: #B71B1B;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #fff;
    cursor: pointer;
    position: relative;
  }
  a.chat-btn-link:hover{
    text-decoration: none;
  }
span.badge {
    font-size: 8px;
    color: #fff;
    background: #292C36;
    width: 14px;
    height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 50%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    top: 10px;
    left: 8px;
}

.speaker-icon.sprite-icon {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-position: -151px -403px;
    cursor: pointer;
    margin-right: 20px;
}

.gray-speaker.speaker-icon.sprite-icon {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-position: -208px -403px;
    cursor: pointer;
    margin-right: 20px;
}

.speaker-mute-icon.sprite-icon {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background-position: -151px -516px;
    cursor: pointer;
    margin-right: 20px;
}

/* left side pannel */

.sidebar {
    width: 243px;
    background: #292C36;
    height: calc(100vh - 64px);
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 64px;
    z-index: 9;
}

.sidebar-backdrop {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 64px);
    left: 0;
    top: 64px;
    z-index: 98;
    display: none;
}

@media(max-width: 1224px) {
    .sidebar-backdrop {
        display: block;
    }
}

.hidden {
    display: none;
}

.collapse-lists {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists a.list-group-item {
    border: none;
    background: transparent;
    border-radius: 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(225, 225, 225, .6);
    text-decoration: none;
    padding: 16px 16px 16px 33px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover, #sidebar .collapse-lists a.list-group-item:hover {
    background: #2F323F;
    font-weight: 500;
    color: #fff;
}

#sidebar .collapse-lists a.list-group-item.active-inner-list {
    background: #B71B1B;
    color: #fff;
    font-weight: 700;
    position: relative;
}

#sidebar .collapse-lists a.list-group-item.active-inner-list:before {
    content: "";
    width: 3px;
    height: 100%;
    background: #B71B1B;
    position: absolute;
    left: -9px;
    top: 0;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.de-active {
    background: transparent;
}

#sidebar .collapse-lists>a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists>a.list-group-item {
    font-size: 16px;
    line-height: 19px;
    color: rgba(225, 225, 225, .6);
    background: transparent;
    padding: 21px 42px 20px 84px;
    border: none;
    border-bottom: 1px solid rgba(169, 170, 174, 0.1);
    border-radius: 0;
    text-decoration: none;
    font-weight: normal;
}

/* #sidebar .collapse-lists > a.list-group-item.d-inline-block.collapsed, #sidebar .collapse-lists > a.list-group-item{
    border-bottom: 1px solid rgba(169,170,174,0.1);
} */

#sidebar .collapse-lists a.list-group-item.d-inline-block {
    background: #B71B1B;
    color: #fff;
    font-weight: 500;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover {
    background: #2F323F;
    color: #fff;
    font-weight: 500;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:last-child {
    border-bottom: none;
}

.sprite-icon {
    background-size: 600px;
}

.sprite-icon1 {
    background-size: 600px;
}

.sprite-icon-before, .sprite-icon-before1 {
    position: relative;
}

.sprite-icon-before:before {
    content: "";
    background-size: 600px;
}

.sprite-icon-before1:before {
    content: "";
    background-size: 600px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.customer {
    background-position: -8px -9px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.customer, #sidebar .collapse-lists a.list-group-item.d-inline-block i.customer {
    background-position: -42px -9px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.dashboard {
    background-position: -136px -8px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.dashboard {
    background-position: -170px -8px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.team {
    background-position: -136px -73px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.team {
    background-position: -170px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.joblink {
    background-position: -136px -100px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.joblink {
    background-position: -170px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.report-list {
    background-position: -135px -127px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.building {
    background-position: -59px -416px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px
}

#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.building {
    background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.report-list {
    background-position: -169px -127px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.task {
    background-position: -135px -157px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.task {
    background-position: -169px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.service-rqst {
    background-position: -135px -183px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 24px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.inventory {
    /* background-position: -136px -216px; */
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
    background: url(img/icons-warehouse.png);
}
#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.inventory{
    /* background-position: -170px -216px; */
    background: url(img/icons-warehouse-hover.png);
}
#sidebar .collapse-lists a.list-group-item.d-inline-block:hover i.inventory {
    /* background-position: -170px -216px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.service-rqst {
    background-position: -169px -183px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block i.deficiencies {
    background-position: -136px -245px;
    width: 28px;
    height: 28px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.deficiencies {
    background-position: -170px -245px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.analytics {
    background-position: -8px -42px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.analytics, #sidebar .collapse-lists a.list-group-item.d-inline-block i.analytics {
    background-position: -42px -42px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.tickets {
    background-position: -8px -76px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.tickets, #sidebar .collapse-lists a.list-group-item.d-inline-block i.tickets {
    background-position: -42px -76px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.reports {
    background-position: -8px -110px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.reports, #sidebar .collapse-lists a.list-group-item.d-inline-block i.reports {
    background-position: -42px -110px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.Settings{    
    /* background-position: -8px -143px;     */
}
#sidebar .collapse-lists a.list-group-item.d-inline-block i.Settings{
    /* background-position: -42px -143px; */
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.Settings {
    /* background-position: -8px -143px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.Settings, #sidebar .collapse-lists a.list-group-item.d-inline-block i.Settings {
    /* background-position: -42px -143px; */
    width: 28px;
    height: 32px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
    background: url(img/icons8-settings-32.png);
    background-position: 0 0;
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.Settings{
    /* background-position: -42px -143px; */
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
    background: url(img/icons8-settings-hover.png);
    background-position: 0 0;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed i.console {
    background-position: -8px -176px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed:hover i.console, #sidebar .collapse-lists a.list-group-item.d-inline-block i.console {
    background-position: -42px -176px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 27px;
}

#sidebar .collapse-lists .menu-design, #sidebar .collapse-lists .menu-design {
    position: relative;
    border-bottom: 1px solid rgba(169, 170, 174, 0.1);
    padding-left: 50px;
    margin-top: 1px;
}

#sidebar .collapse-lists .menu-design a:last-child, #sidebar .collapse-lists .menu-design a:last-child {
    margin-bottom: 19px;
}

/* #sidebar .collapse-lists .show{
    padding-bottom: 19px;
} */

#sidebar .collapse-lists .menu-design:before, #sidebar .collapse-lists .menu-design:before {
    content: "";
    position: absolute;
    height: calc( 100% - 19px);
    width: 1px;
    background: rgba(225, 225, 225, .2);
    left: 42px;
    top: 1px;
}

/* #sidebar .collapse-lists .menu-design.show a.list-group-item{
    background: #292C36;
    font-weight: normal;
    color: #fff;
    font-weight: 700;
}
#sidebar .collapse-lists .menu-design.show a.list-group-item.collapsed{
    background: #B71B1B;
    color: #fff;
    font-weight: 700;
} */

/* end of left side pannel */

/* table css */

.global-table {
    border-radius: 4px;
    border: 1px solid #CBD1D5;
}

.global-table .table {
    margin-bottom: 0;
}

/* .global-table .table td, .global-table .table th {
    border-top: none;
} */

.admin-table.global-table>table {
    border-radius: 4px;
    overflow: hidden;
    margin: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    /* background-color: #E4E5E6; */
    background-color: #f2f2f2;
}

.table thead tr th {
    border-top: none;
    height: 60px;
    vertical-align: middle;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}

.table-striped tbody tr {
    position: relative;
}

.table-striped tbody tr tbody tr:hover {
    /* box-shadow: 0 0 4px 3px rgba(0,0,0,0.13); */
    background: rgba(47, 50, 63, .2);
}

.table-striped tbody tr tbody tr:last-child {
    border-bottom: 1px solid #CBD1D5;
}

.table-striped tbody tr .open-icons.display-eye {
    display: block;
    right: 48px;
}

.ellipses-icon {
    display: block;
}

.table-striped tbody tr tbody tr:hover .open-icons {
    display: block;
}

.table-striped tbody tr:hover .open-icons .delete, .table-striped tbody tr:hover .open-icons .graph, .table-striped tbody tr:hover .open-icons .eye, .table-striped tbody tr:hover .open-icons .refresh, .table-striped tbody tr:hover .open-icons .download, .table-striped tbody tr:hover .open-icons .email, .table-striped tbody tr:hover .open-icons .user {
    position: relative;
}

.table-striped tbody tr:hover .open-icons.display-eye .eye {
    position: absolute;
}

.table-striped tbody tr:hover .open-icons .delete:hover, .table-striped tbody tr:hover .open-icons .graph:hover, .table-striped tbody tr:hover .open-icons .eye:hover {
    cursor: pointer;
}

.table-striped tbody tr:hover .open-icons .delete:hover:before, .table-striped tbody tr:hover .open-icons .graph:hover:before, .table-striped tbody tr:hover .open-icons .eye:hover:before, .table-striped tbody tr:hover .open-icons .refresh:hover:before, .table-striped tbody tr:hover .open-icons .user:hover:before, .table-striped tbody tr:hover .open-icons .email:hover:before, .table-striped tbody tr:hover .open-icons .download:hover:before, .table-striped tbody tr:hover .open-icons .edit:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: -7px;
}

.table-striped tbody tr:hover .open-icons .delete:hover:after {
    content: "Delete";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .eye:hover:after {
    content: "View";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .graph:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .refresh:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .user:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .email:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .download:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr:hover .open-icons .edit:hover:after {
    content: "Graph";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped tbody tr tbody tr:hover .ellipses-icon {
    display: none;
}

.admin-table table table tbody tr td:last-child {
    position: relative;
}

.open-icons {
    position: absolute;
    top: 17px;
    right: 30px;
}

.delete-icon {
    display: inline-block;
  /* width: 20px; */
  width: 16px;
  height: 20px;
  background-position: -18px -310px;
  position: absolute;
  /* right: 34px; */
  right: 30px;
  top: 20px;
  cursor: pointer;
  background: url(img/delete-vr-2.png);
}

.delete-icon:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: -9px;
}

.delete-icon:not(.no-title):hover:after {
    content: "Delete";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.edit-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: -173px -374px;
    position: absolute;
    right: 68px;
    top: 20px;
    cursor: pointer;
    background: url(img/edit-vr-2.png);
}

.edit-icon:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: -7px;
}

.edit-icon:hover:after {
    content: "Edit";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

span.pdf-icon.sprite-icon {
    width: 26px;
    height: 26px;
    background-position: -457px -431px;
    position: absolute;
    top: 16px;
    right: 30px;
}

span.word-icon.sprite-icon {
    width: 26px;
    height: 26px;
    background-position: -458px -385px;
    position: absolute;
    top: 16px;
    right: 75px;
}

span.note-icon.sprite-icon {
    width: 26px;
    height: 26px;
    background-position: -457px -339px;
    position: absolute;
    top: 16px;
    right: 125px;
}

.table thead {
    background: #B71B1B;
}

.table thead tr th {
    border-bottom: none;
}

/* .admin-table table table tbody tr td:first-child {
    padding-left: 25px;
    width: 22%;
    font-weight: 500;   
} */

/* .admin-table.global-table .table thead tr th:first-child {
    padding-left: 45px;
    width: 20%;
} */

.title-class {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 144px;
    display: inline-block;
}

.table .arrow span {
    position: relative;
    display: table;
}

.table .arrow span:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 11px;
    height: 8px;
    right: -16px;
    background-position: -76px -64px;
    top: 3px;
}

.table .arrow span:after {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 11px;
    height: 8px;
    right: -16px;
    background-position: -76px -74px;
    top: 12px;
}

.admin-table.global-table .table tbody tr td table tbody tr td {
    width: 18%;
}

.admin-table.global-table .table thead tr th {
    width: 18%;
}

.admin-table.global-table .table tbody tr td table tbody tr td:last-child {
    min-width: 104px;
}

.admin-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
    text-align: left;
    padding-left: 62px;
}

.global-table.admin-table .table thead tr th:nth-child(5) {
    padding-left: 34px;
    text-align: left;
}

/* small table css */

.global-sm-table .table thead tr th {
    height: 30px;
}

.global-table.global-sm-table table table tbody tr td {
    height: 30px;
}

.global-sm-table .delete-icon {
    top: 5px;
    background-size: 400px;
    background-position: -305px -163px;
    cursor: pointer;
}

.global-sm-table .delete-icon:hover:before {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -2px;
    left: -6px;
}

.global-sm-table tbody tr tbody tr:last-child .delete-icon:hover:before {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -2px;
    left: -6px;
}

.global-sm-table tbody tr tbody tr:last-child .delete-icon:hover:after {
    top: -30px;
    z-index: 9;
}

.global-sm-table .delete-icon:hover:after {
    top: 25px;
    z-index: 9;
}

/* .admin-table.global-table .table thead tr th:nth-child(2) {
    padding-left: 12px;
    width: 12%;
}
.admin-table.global-table .table thead tr th:nth-child(3) {
    padding-left: 32px;
    width: 16%;
}
.admin-table.global-table .table thead tr th:nth-child(5) {
    width: 15%;
    text-align: center;
}
.admin-table.global-table .table thead tr th:nth-child(4) {
    width: 18%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 15%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(3){
    width: 14%;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(4){
    width: 18%;
    text-align: center;
}
.global-table.admin-table .table tbody tr td table tbody tr td:nth-child(5){
    width: 22%;
    padding-left: 60px;
} */

.trial, .Subscibe, .expired, .canceled, .unsubscribe, .free-trial {
    position: relative;
    font-weight: 500;
}

.trial {
    color: #4DBCE9;
}

.resolved {
    color: #4DBCE9;
    font-weight: 500;
}

.Subscibe {
    color: #6AA14E;
}

.expired {
    color: #E76C04
}

.canceled {
    color: #E33945;
}

.unsubscribe {
    color: #999999;
}

.free-trial {
    color: #C261FF;
}

.trial:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -10px -459px;
    top: 2px;
    left: -16px;
}

.Subscibe:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -11px -394px;
    top: 2px;
    left: -16px;
}

.expired:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -10px -416px;
    top: 2px;
    left: -16px;
}

.canceled:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -10px -438px;
    top: 2px;
    left: -16px;
}

.unsubscribe:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -10px -504px;
    top: 2px;
    left: -16px;
}

.free-trial:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -16px;
    background-position: -10px -482px;
    top: 2px;
    left: -16px;
}

/* end of left pannel css */

/* Report panel css */

.report-container {
    -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #000000;
    background-color: #F9F9F9;
    width: 327px;
    height: auto;
}

.report-container .report-head {
    -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    height: 68px;
    background-color: #292C36;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
}

.report-container .report-head p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
}

.report-container .report-body {
    padding: 20px 6px 20px 0;
}

.report-container .report-body .report-list ul {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.report-container .report-body .report-list ul li {
    width: 82px;
    height: 84px;
    border: 1px solid #B71B1B;
    border-radius: 4px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
    margin-left: 19px;
    margin-bottom: 20px;
}

.report-container .report-body .report-list ul li .report-lists .report-icon {
    min-height: 43px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.report-container .report-body .report-list ul li .report-lists .report-icon>div {
    height: 43px;
    width: 40px;
    margin: 0 auto;
}

.report-container .report-body .report-list ul li:hover {
    background: #B71B1B;
}

.report-container .report-body .report-list ul li.exit .report-lists .report-icon, .report-container .report-body .report-list ul li.special .report-lists .report-icon, .report-container .report-body .report-list ul li.GreaseCleaning .report-lists .report-icon, .report-container .report-body .report-list ul li.hose .report-lists .report-icon, .report-container .report-body .report-list ul li.hood .report-lists .report-icon {
    margin-bottom: 3px;
    margin-top: 6px;
}

.report-container .report-body .report-list ul li.exit .report-lists .report-icon .exit-in {
    background-position: -266px -9px;
}

.report-container .report-body .report-list ul li.exit:hover .report-lists .report-icon .exit-in {
    background-position: -206px -9px;
}

.report-container .report-body .report-list ul li.FirePump .report-lists .report-icon .FirePump-in {
    background-position: -268px -55px;
    width: 44px;
}

.report-container .report-body .report-list ul li.FirePump:hover .report-lists .report-icon .FirePump-in {
    background-position: -208px -54px;
    width: 44px;
}

.report-container .report-body .report-list ul li.flow .report-lists .report-icon .flow-in {
    background-position: -268px -140px;
    width: 44px
}

.report-container .report-body .report-list ul li.flow:hover .report-lists .report-icon .flow-in {
    background-position: -208px -140px;
}

.report-container .report-body .report-list ul li.security .report-lists .report-icon .security-in {
    background-position: -269px -235px;
    width: 48px;
}

.report-container .report-body .report-list ul li.security:hover .report-lists .report-icon .security-in {
    background-position: -208px -235px;
}

.report-container .report-body .report-list ul li.Extinguisher .report-lists .report-icon .Extinguisher-in {
    background-position: -263px -183px;
}

.report-container .report-body .report-list ul li.Extinguisher:hover .report-lists .report-icon .Extinguisher-in {
    background-position: -202px -183px;
}

.report-container .report-body .report-list ul li.special .report-lists .report-icon .special-in {
    background-position: -270px -334px;
    width: 42px;
}

.report-container .report-body .report-list ul li.special:hover .report-lists .report-icon .special-in {
    background-position: -209px -334px;
}

.report-container .report-body .report-list ul li.FireDoor .report-lists .report-icon .FireDoor-in {
    background-position: -328px -8px;
    width: 46px;
}

.report-container .report-body .report-list ul li.FireDoor:hover .report-lists .report-icon .FireDoor-in {
    background-position: -393px -8px;
}

.report-container .report-body .report-list ul li.sprinkler .report-lists .report-icon .sprinkler-in {
    background-position: -262px -287px;
}

.report-container .report-body .report-list ul li.sprinkler:hover .report-lists .report-icon .sprinkler-in {
    background-position: -202px -287px;
}

.report-container .report-body .report-list ul li.GreaseCleaning .report-lists .report-icon .GreaseCleaning-in {
    background-position: -330px -103px;
    width: 50px;
}

.report-container .report-body .report-list ul li.GreaseCleaning:hover .report-lists .report-icon .GreaseCleaning-in {
    background-position: -395px -103px;
}

.report-container .report-body .report-list ul li.hose .report-lists .report-icon .hose-in {
    background-position: -320px -152px;
    width: 50px;
}

.report-container .report-body .report-list ul li.hose:hover .report-lists .report-icon .hose-in {
    background-position: -384px -152px;
}

.report-container .report-body .report-list ul li.hood .report-lists .report-icon .hood-in {
    background-position: -330px -56px;
}

.report-container .report-body .report-list ul li.hood:hover .report-lists .report-icon .hood-in {
    background-position: -395px -56px;
}

.report-container .report-body .report-list ul li.hydrant .report-lists .report-icon .hydrant-in {
    background-position: -323px -254px;
}

.report-container .report-body .report-list ul li.hydrant:hover .report-lists .report-icon .hydrant-in {
    background-position: -388px -254px;
}

.report-container .report-body .report-list ul li.monitoring .report-lists .report-icon .monitoring-in {
    background-position: -319px -466px;
}

.report-container .report-body .report-list ul li.monitoring:hover .report-lists .report-icon .monitoring-in {
    background-position: -384px -466px;
}

.report-container .report-body .report-list ul li.sensitivity .report-lists .report-icon .sensitivity-in {
    background-position: -329px -411px;
    width: 54px;
}

.report-container .report-body .report-list ul li.sensitivity:hover .report-lists .report-icon .sensitivity-in {
    background-position: -394px -411px;
}

.report-container .report-body .report-list ul li.Other .report-lists .report-icon .Other-in {
    background-position: -329px -360px;
}

.report-container .report-body .report-list ul li.Other:hover .report-lists .report-icon .Other-in {
    background-position: -395px -360px;
}

.report-container .report-body .report-list ul li.quote .report-lists .report-icon .quote-in {
    background-position: -271px -385px;
}

.report-container .report-body .report-list ul li.quote:hover .report-lists .report-icon .quote-in {
    background-position: -271px -437px;
}

.report-container .report-body .report-list ul li .report-lists p {
    margin: 0;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #000;
}

.report-container .report-body .report-list ul li:hover p {
    color: #fff;
    font-weight: 500;
}

/* end of Report panel css */

/* deficiencies table css */

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(1) {
    width: 18%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 10%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 16%;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 26%;
    padding-left: 20px;
    text-align: left;
}

.global-table.deficiencies-table .table tbody tr td table tbody tr td:nth-child(7) {
    width: 10%;
}

.global-table.deficiencies-table .table thead tr th:first-child {
    width: 18%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(2) {
    width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(3) {
    width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(4) {
    width: 10%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(5) {
    width: 16%;
}

.global-table.deficiencies-table .table thead tr th:nth-child(6) {
    width: 26%;
    text-align: left;
}

.global-table.deficiencies-table .table thead tr th:nth-child(7) {
    width: 10%;
}

/* email icon on table css */

.email-icon.sprite-icon-before:before {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -28px;
    top: -4px;
    background-position: -31px -474px;
    cursor: pointer;
}

.email-icon.sprite-icon-before.email-icon-pos:before {
    right: 0;
    top: -2px;
}

/* end of email icon on table css */

/* toggle button css */

.tgl {
    display: none;
}

.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl+.tgl-btn {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl+.tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl+.tgl-btn::selection {
    background: none;
}

.tgl+.tgl-btn {
    outline: 0;
    display: block;
    width: 34px;
    height: 14px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl+.tgl-btn:after, .tgl+.tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl+.tgl-btn:after {
    left: 0;
}

.tgl+.tgl-btn:before {
    display: none;
}

.tgl:checked+.tgl-btn:after {
    left: 57%;
}

.tgl-light+.tgl-btn {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2em;
    padding: 2px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.tgl-light+.tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    width: 20px;
    height: 20px;
    position: absolute;
    left: -1px;
    top: -3px;
}

.tgl-light:checked+.tgl-btn {
    background: #B71B1B;
}

.tgl-ios+.tgl-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border: 1px solid #e8eae9;
}

.tgl-ios+.tgl-btn:after {
    border-radius: 2em;
    background: #fbfbfb;
    -webkit-transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.tgl-ios+.tgl-btn:hover:after {
    will-change: padding;
}

.tgl-ios+.tgl-btn:active {
    -webkit-box-shadow: inset 0 0 0 2em #e8eae9;
            box-shadow: inset 0 0 0 2em #e8eae9;
}

.tgl-ios+.tgl-btn:active:after {
    padding-right: .8em;
}

.tgl-ios:checked+.tgl-btn {
    background: #86d993;
}

.tgl-ios:checked+.tgl-btn:active {
    -webkit-box-shadow: none;
            box-shadow: none;
}

.tgl-ios:checked+.tgl-btn:active:after {
    margin-left: -.8em;
}

.tgl-skewed+.tgl-btn {
    overflow: hidden;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-family: sans-serif;
    background: #888;
}

.tgl-skewed+.tgl-btn:after, .tgl-skewed+.tgl-btn:before {
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
    display: inline-block;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.tgl-skewed+.tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}

.tgl-skewed+.tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}

.tgl-skewed+.tgl-btn:active {
    background: #888;
}

.tgl-skewed+.tgl-btn:active:before {
    left: -10%;
}

.tgl-skewed:checked+.tgl-btn {
    background: #86d993;
}

.tgl-skewed:checked+.tgl-btn:before {
    left: -100%;
}

.tgl-skewed:checked+.tgl-btn:after {
    left: 0;
}

.tgl-skewed:checked+.tgl-btn:active:after {
    left: 10%;
}

.tgl-flat+.tgl-btn {
    padding: 2px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    background: #fff;
    border: 4px solid #f2f2f2;
    border-radius: 2em;
}

.tgl-flat+.tgl-btn:after {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    background: #f2f2f2;
    content: "";
    border-radius: 1em;
}

.tgl-flat:checked+.tgl-btn {
    border: 4px solid #7FC6A6;
}

.tgl-flat:checked+.tgl-btn:after {
    left: 50%;
    background: #7FC6A6;
}

.tgl-flip+.tgl-btn {
    padding: 2px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-family: sans-serif;
    -webkit-perspective: 100px;
    perspective: 100px;
}

.tgl-flip+.tgl-btn:after, .tgl-flip+.tgl-btn:before {
    display: inline-block;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 2em;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 4px;
}

.tgl-flip+.tgl-btn:after {
    content: attr(data-tg-on);
    background: #02C66F;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.tgl-flip+.tgl-btn:before {
    background: #FF3A19;
    content: attr(data-tg-off);
}

.tgl-flip+.tgl-btn:active:before {
    -webkit-transform: rotateY(-20deg);
    transform: rotateY(-20deg);
}

.tgl-flip:checked+.tgl-btn:before {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.tgl-flip:checked+.tgl-btn:after {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    left: 0;
    background: #7FC6A6;
}

.tgl-flip:checked+.tgl-btn:active:after {
    -webkit-transform: rotateY(20deg);
    transform: rotateY(20deg);
}

/* end of toggle button css */

/* main container css */

.main-container {
    /* width: calc(100% - 243px);
    background: #E4E5E6;
    padding: 30px 60px 0 30px; */
    width: 100%;
    background: #E4E5E6;
    padding: 30px 60px 0 30px;
    padding-left: 273px;
    height: calc(100vh - 64px);
    overflow: auto;
}

.main-container.add-side-report {
    /* width: calc(100% - 570px);
    padding: 30px; */
    width: calc(100% - 327px);
    padding: 30px;
    padding-left: 273px;
}

/* use only one */

.top-search-field, .top-search-feild {
    margin-bottom: 24px;
}

.search-container input {
    height: 40px;
    border: 1px solid #C4C4C4;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #fff;
    padding: 12px;
    min-width: 356px;
    border-right: none;
}

#master-acc .search-container input {
    min-width: calc(100% - 75px);
}

.search-container button {
    position: relative;
}

.search-container button .search-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    background-position: -30px -392px;
    left: 28px;
    color: #fff;
}

.search-container input:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.search-container button {
    background: #B71B1B;
    width: 75px;
    height: 40px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
}

.search-container button:focus {
    outline: none;
}

/* small search area */

.sm-search-fld.search-container button {
    height: 30px;
}

.sm-search-fld.search-container input {
    min-width: 230px;
    height: 30px;
}

.sm-search-fld.search-container button .search-icon {
    top: 6px;
}

/* end of small search */

.filter-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
}

.filter-section label {
    color: #4C4C4C;
    ;
    font-weight: 500;
    padding-right: 15px;
}

.filter-section span.clip {
    height: 40px;
    width: 92px;
    border-radius: 4px;
    background: #dddddd;
    color: #4c4c4c;
    text-align: center;
    display: inline-block;
}

.ellipses-icon {
    position: relative;
}

.ellipses-icon:before {
    content: "";
    background-size: 600px;
    position: absolute;
    width: 28px;
    height: 10px;
    right: -16px;
    background-position: -60px -482px;
    top: -3px;
    right: 44px;
}

/* cutom  model */

.modal-sub-heading {
    background: #fff;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 24px;
}

.modal-sub-heading.in {
    width: calc(100% + 48px);
    margin-left: -23px;
}

.modal-sub-heading p {
    margin: 0;
    color: #B71B1B;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
}

.customer-detail-modal {
    width: 876px;
    height: auto;
    position: fixed;
    top: 64px;
    z-index: 99;
    right: -876px;
    /* background: red; */
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.customer-detail-modal.open {
    right: 0;
}

.customer-detail-modal .heading-panel {
    background: #292C36;
    height: 64px;
    padding: 22px 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    border-bottom: 2px solid #B71B1B;
}

.heading-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #fff;
}

.customer-modal-body {
    background: #fff;
    padding: 22px 24px 24px;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    border: 1px solid #C4C4C4;
    border-top: 0;
}

.add-customer-modal .customer-modal-body {
    background: #fff;
    padding: 22px 24px 24px;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    border: none;
    border-top: 0;
    background: #f5f5f5;
}

.cross {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    background-position: -30px -418px;
    cursor: pointer;
}

.btn-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    margin-top: 26px;
}

.btn-section button {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
}

.btn-section button:nth-child(2) {
    margin: 0 24px;
}

/* Analytics table css */

.analytics-table .tab-report-icons {
    position: absolute;
    background-position: -18px -357px;
    width: 20px;
    height: 20px;
    top: 18px;
    right: 30px;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    cursor: pointer;
}

.analytics-table .tab-report-icons:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: -7px;
}

.analytics-table .tab-report-icons:hover:after {
    content: "Reports";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.table-striped th table tbody tr:nth-of-type(odd) {
    background-color: transparent;
}

.global-table .table thead tr th, .global-table .table thead tr th:first-child, .global-table .table thead tr th:nth-child(2), .global-table .table thead tr th:nth-child(3), .global-table .table thead tr th:nth-child(4), .global-table .table thead tr th:nth-child(5) {}

.global-table .table thead tr th {
    text-align: center;
}

.global-table .table thead tr th:first-child {
    text-align: left;
}

.global-table.analytics-table .table thead tr th:first-child {
    width: 12%;
    text-align: left;
}

.global-table.analytics-table .table thead tr th:nth-child(2) {
    width: 20%;
}

.global-table.analytics-table .table thead tr th:nth-child(3) {
    width: 15%;
}

.global-table.analytics-table .table thead tr th:nth-child(4) {
    width: 15%;
}

.global-table.analytics-table .table thead tr th:nth-child(5) {
    width: 17%;
}

.global-table.analytics-table .table thead tr th:nth-child(6) {
    width: 18%;
}

.global-table.analytics-table .table thead tr th:nth-child(7) {
    width: 6%;
}

/* Supplier-table css */

.global-table.Supplier-table .table thead tr th:first-child {
    width: 12%;
}

.global-table.Supplier-table .table tbody tr td table tbody tr td:first-child {
    width: 12%;
}

.Supplier-table .edit-icon {
    right: 30px;
}

/* end of Supplier-table css *

/* td width */

.global-table .table tbody tr td table tbody tr td {
    text-align: center;
}

.global-table .table tbody tr td table tbody tr td:first-child {
    text-align: left;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:first-child {
    width: 12%;
    text-align: left;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 20%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 15%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 15%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 17%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 18%;
}

.global-table.analytics-table .table tbody tr td table tbody tr td:nth-child(7) {
    width: 6%;
    position: relative;
}

.cus-inter-table.global-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 10%;
}

.cus-inter-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 8%;
}

.cus-inter-table.global-table .table thead tr th:nth-child(2) {
    width: 10%;
}

.cus-inter-table.global-table .table thead tr th:nth-child(5) {
    width: 8%;
}

.customer-in-table.global-table .table tbody tr td table tbody tr td:first-child {
    width: 8%;
}

.customer-in-table.global-table .table thead tr th:first-child {
    width: 8%;
}

span.open-icons.fixed-width-icon {
    width: 172px;
}

span.open-icons.fixed-width-icon span {
    margin-left: 8px;
}

span.open-icons.fixed-width-icon span.edit {
    left: -11px;
    top: 2px;
}

.global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(4) {
    text-align: left;
}

.global-table.deficiency-table .table thead tr th:nth-child(4) {
    text-align: left;
    left: -13px;
    position: relative;
}

.global-table.system-info .table thead tr th:nth-child(5) {
    width: 10%;
}

.global-table.system-info .table tbody tr td table tbody tr td:nth-child(5) {
    width: 10%;
}

/* .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(1), .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(2), .global-table.deficiency-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 12%;
} 
.global-table.deficiency-table .table thead tr th:nth-child(3){
    width: 12%;
}
.global-table.deficiency-table .table thead tr th:first-child{
    width: 12%;
}
.global-table.deficiency-table .table thead tr th:nth-child(2) {
    width: 16%;
} */

.task-table.global-table .table tbody tr td table tbody tr td:nth-child(1) {
    width: 12%;
}

.task-table.global-table .table tbody tr td table tbody tr td:nth-child(3), .task-table.global-table .table tbody tr td table tbody tr td:nth-child(4), .task-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 15%;
}

.task-table.global-table .table thead tr th:nth-child(5), .task-table.global-table .table thead tr th:nth-child(4), .task-table.global-table .table thead tr th:nth-child(3) {
    width: 15%;
}

.task-table.global-table .table thead tr th:nth-child(1) {
    width: 12%;
}

.global-table.bind-info-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 28%;
}

.global-table.bind-info-table .table thead tr th:nth-child(2) {
    width: 28%;
}

/* custom popup table css */

.scroll-wrap {
    max-height: calc(100vh - 128px);
    /* overflow-y: scroll;
    overflow-x: hidden; */
}

.customer-detail-modal.customer-modal-table {
    /* width: 586px; */
    width: 610px;
}

.customer-modal-table .customer-modal-body {
    padding: 0;
    border: none;
}

.customer-modal-table .customer-modal-body .global-table {
    border-radius: 0;
    border: 0;
}

/* nationwide table */

.global-table.nationwide-table .table tbody tr td table tbody tr td:first-child {
    width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 30%;
}

.global-table.nationwide-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 10%;
    position: relative;
}

.global-table.nationwide-table .table thead tr th:first-child {
    width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(2) {
    width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(3) {
    width: 30%;
}

.global-table.nationwide-table .table thead tr th:nth-child(4) {
    width: 10%;
}

/* nationwide-2 table */

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:first-child {
    width: 65%;
}

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:nth-child(2) {
    width: 23%;
}

.global-table.nationwide-table-2 .table tbody tr td table tbody tr td:nth-child(3) {
    width: 12%;
    position: relative;
}

.global-table.nationwide-table-2 .table thead tr th:first-child {
    width: 65%;
}

.global-table.nationwide-table-2 .table thead tr th:nth-child(2) {
    width: 23%;
}

.global-table.nationwide-table-2 .table thead tr th:nth-child(3) {
    width: 12%;
}

/* customer table css */

.customer-table .table-striped tbody tr tbody tr:last-child {
    border-bottom: none;
}

.global-table.customer-table .table tbody tr td table tbody tr td:first-child {
    width: 20%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 14%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 16%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 15%;
}

.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 13%;
}

.global-table.customer-table .table thead tr th:first-child {
    width: 20%;
}

.global-table.customer-table .table thead tr th:nth-child(2) {
    width: 14%;
}

.global-table.customer-table .table thead tr th:nth-child(3) {
    width: 16%;
}

.global-table.customer-table .table thead tr th:nth-child(4) {
    width: 15%;
}

.global-table.customer-table .table thead tr th:nth-child(5) {
    width: 13%;
}

/* view report table css */

.global-table.view-report-table .table thead tr th:nth-child(2) {
    width: 15%;
}

.global-table.view-report-table .table thead tr th:nth-child(3) {
    width: 15%;
}

.global-table.view-report-table .table thead tr th:nth-child(4) {
    width: 18%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 15%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 15%;
}

.global-table.view-report-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 18%;
}

/* report table css */

.global-table.report-table .table tbody tr td table tbody tr td:first-child {
    width: 20%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 14%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 16%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 15%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 13%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 10%;
}

.global-table.report-table .table tbody tr td table tbody tr td:nth-child(7) {
    width: 12%;
}

.global-table.report-table .table thead tr th:first-child {
    width: 20%;
}

.global-table.report-table .table thead tr th:nth-child(2) {
    width: 14%;
}

.global-table.report-table .table thead tr th:nth-child(3) {
    width: 16%;
}

.global-table.report-table .table thead tr th:nth-child(4) {
    width: 15%;
}

.global-table.report-table .table thead tr th:nth-child(5) {
    width: 13%;
}

.global-table.report-table .table thead tr th:nth-child(6) {
    width: 10%;
}

.global-table.report-table .table thead tr th:nth-child(7) {
    width: 12%;
}

/* inspection table css */

.global-table.inspection-table .table thead tr th:first-child {
    width: 10%;
}

.global-table.inspection-table .table tbody tr td table tbody tr td:first-child {
    width: 10%;
}

/* recent tble css */

.global-table.recent-table .table tbody tr td table tbody tr td:first-child {
    width: 10%;
}

.global-table.recent-table .table thead tr th:first-child {
    width: 10%;
}

/* incomplete table css */

.global-table.incomplete-table .table thead tr th:first-child {
    width: 10%;
}

.global-table.incomplete-table .table thead tr th:nth-child(2) {
    width: 15%;
}

.global-table.incomplete-table .table thead tr th:nth-child(3) {
    width: 18%;
}

.global-table.incomplete-table .table thead tr th:nth-child(4) {
    width: 12%;
}

.global-table.incomplete-table .table thead tr th:nth-child(5) {
    width: 15%;
}

.global-table.incomplete-table .table thead tr th:nth-child(6) {
    width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 12%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 18%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 15%;
}

.global-table.incomplete-table .table tbody tr td table tbody tr td:nth-child(1) {
    width: 10%;
}

/* service request table css */

.global-table.service-req-table .table thead tr th:nth-child(1) {
    width: 15%;
}

.global-table.service-req-table .table thead tr th:nth-child(4) {
    width: 25%;
}

.global-table.service-req-table .table thead tr th:nth-child(5) {
    text-align: left;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:last-child {
    text-align: left;
    padding-left: 20px;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:nth-child(1) {
    width: 15%;
}

.global-table.service-req-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 25%;
}

/* analytic screen css */

.year-selection .custom-select {
    min-width: 106px;
    /* height: 40px; */
}

/* .year-selection .select-selected{
    height: 40px;
} */

.main-container.container-dash-bg {
    position: relative;
}

.main-container.container-dash-bg:before {
    display: block;
    content: "";
    height: 367px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
}

.container-dash-bg h1 {
    color: #ffffff;
    position: relative;
}

.analytic-boxes ul {
    padding-left: 0;
}

.analytic-boxes .analytic-box {
    -webkit-box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
            box-shadow: 0 6px 18px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    height: 150px;
    /* width: 324px; */
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    padding-left: 28px;
    position: relative;
}

.analytic-boxes .analytic-box:before {
    content: "";
    width: 108px;
    height: 140px;
    position: absolute;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
}

.analytic-boxes .analytic-no {
    padding-right: 20px;
    color: #2F353B;
    font-size: 30px;
    font-weight: 500;
    line-height: 35px;
}

.analytic-boxes .analytic-text {
    font-size: 16px;
    line-height: 24px;
    color: #4C4C4C;
    max-width: 110px;
}

.analytic-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.analytic-container .flex-size {
    /* flex: 1;
    -webkit-flex:1 */
    width: 33.333%;
    margin-bottom: 30px;
}

.graph-section img {
    max-width: 104%;
    width: 106%;
    margin-left: -2%;
    margin-top: -15px;
}

/* nationwide css */

.search-with-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.search-with-btn .search-container {
    margin-right: 30px;
}

.cust-select-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    margin-top: 24px;
}

.cust-select-btns button {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
}

.cust-select-btns button:first-child {
    margin-right: 24px;
}

/* Report screnn css */

.next-btn-wrap {
    text-align: center;
    margin-top: 24px;
}

.select-group-sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.select-group-sec .firt-select, .select-group-sec .second-select {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
}

.select-group-sec .firt-select {
    margin-right: 8px;
}

.filter-btn-grp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
    margin-bottom: 20px;
}

.filter-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.appliyed-filter .custom-chip {
    margin-left: 8px;
}

.appliyed-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
}

.appliyed-filter span {
    color: #4c4c4c;
    font-weight: 500;
    font-size: 14px;
}

.search-list {
    margin-top: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.search-list .search-chip {
    margin-right: 8px;
    margin-top: 8px;
}

.active-list {
    background: #B71B1B !important;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list.active-list {
    color: #fff;
    font-weight: 500;
    width: 244px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.analytics {
    background-position: -42px -42px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.console {
    background-position: -42px -176px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 27px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.customer {
    background-position: -42px -9px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.team {
    background-position: -170px -73px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.task {
    background-position: -169px -157px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.building {
    background-position: -93px -416px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.dashboard {
    background-position: -170px -8px;
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.Settings{
    /* background-position: -42px -143px; */
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.Settings {
    /* background-position: -42px -143px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.service-rqst {
    background-position: -169px -183px
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.report-list {
    background-position: -169px -127px
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.building {
    background-position: -93px -416px;
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.inventory{
    /* background-position:-170px -216px; */
     background: url(img/icons-warehouse-hover.png);
}
#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.inventory {
    /* background-position: -170px -216px; */
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.joblink {
    background-position: -170px -100px;
}

#sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list i.reports {
    background-position: -42px -110px;
    width: 28px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 18px;
}

/*upto here */

/* subscription card css */

.subscription-icon {
    width: 36px;
    height: 46px;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
}

.subscription-icon:before {
    width: 36px;
    height: 46px;
    background-position: -97px -9px;
    position: absolute;
    left: 0;
}

.subdcription-heading {
    color: #4C4C4C;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
}

.subdcription-heading+p {
    text-align: center;
    margin-bottom: 0;
    color: #4C4C4C;
}

.card-body.pad-20 {
    padding: 20px 20px 30px 20px;
}

.card-footer .rate {
    margin: 0;
    font-size: 32px;
    color: #B71B1B;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
}

.card-footer .rate .dolr {
    color: #4C4C4C;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
}

.card-footer .rate .month {
    color: #4C4C4C;
    font-size: 12px;
    line-height: 19px;
}

.card-footer {
    background: rgba(235, 235, 235, .23);
    border-top: none;
    padding: 8px 10px;
}

.subscription-icon {}

/* search list item css */

.search-list-items ul {
    padding-left: 0;
    margin-bottom: 24px;
}

.search-list-items ul li {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-align-items: center;
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-left: 24px;
}

.search-list-items ul li.list-bg {
    background: rgba(255, 183, 188, 0.2) !important;
}

.search-list-items ul li:nth-child(odd) {
    background: rgba(76, 76, 76, .03)
}

/* custom scroll css */

.mCSB_scrollTools .mCSB_draggerRail {
    width: 0px !important
}

.mCSB_draggerContainer #mCSB_1_dragger_vertical {
    max-height: 160px !important;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #cacaca !important;
}

.mCSB_inside>.mCSB_container {
    margin-right: 0 !important;
}

.mCSB_outside+.mCSB_scrollTools {
    right: 0 !important;
}

/* end of custom scroll css */

/* table scroll css */

.table-wrap-scroll {
    position: relative;
}

.table-body-scroll {
    max-height: calc(100vh - 128px);
    overflow: auto;
}

/* customer home screen css */

.state-tax {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
}

.state-tax p {
    margin: 0;
    margin-right: 10px;
    font-weight: 500;
    color: #4c4c4c;
}

.tax-heading-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.mark-lists {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.mark-lists label {
    margin-right: 12px;
}

.mark-lists label:last-child {
    margin-right: 0px;
}

.selected-month-text {
    margin-top: 10px;
}

.add-task-inspection {
    position: relative;
}

.info-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 2px;
    background-position: -60px -483px;
    cursor: pointer;
}

.add-another-task {
    font-size: 16px;
    margin-top: 24px;
    color: #DD231D;
    padding-left: 24px;
}

.modal-footer input.form-control {
    background: #fff;
}

.modal-footer .select-selected {
    background: #fff;
}

label.editable-text {
    padding-right: 36px;
    position: relative;
}

label.editable-text .edit-label.sprite-icon1 {
    width: 15px;
    height: 20px;
    position: absolute;
    right: 28px;
    background-position: -61px -571px;
    cursor: pointer;
}

label.editable-text .del-label.sprite-icon1 {
    width: 15px;
    height: 20px;
    position: absolute;
    right: 0px;
    background-position: -61px -545px;
    cursor: pointer;
}

/* BUilding image info screen css */

.building-info-wrap {
    margin-bottom: 30px;
}

.upload-img-wrap {
    background: #eee;
    margin-bottom: 12px;
    border-radius: 4px;
    height: 110px;
    overflow: hidden;
    text-align: center;
}

.upload-img-wrap img {
    max-width: 100%;
}

.maximize-icon {
    position: absolute;
    right: 27px;
    top: 12px;
    width: 20px;
    height: 20px;
    background-position: -10px 24px;
}

.name-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding-right: 72px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
}

.name-wrap .flname {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #B71B1B;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
}

.name-text-wrap p {
    margin-bottom: 0;
    color: #4c4c4c;
}

.name-text-wrap p.name-heading {
    font-size: 16px;
    font-weight: 500;
}

.read-more, .read-more:hover {
    color: #B71B1B;
    font-size: 12px;
    text-decoration: underline;
    line-height: 14px;
}

.comment-details {
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
}

.cmnt-textarea textarea {
    margin-bottom: 0;
}

.cmnt-detail-list .flname {
    background: #4DBCE9;
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 12px;
}

.comment-details.with-border {
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 0;
    padding-bottom: 12px;
}

.cmnt-detail-list {
    padding-left: 30px;
    padding-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
}

.modification-items {
    position: relative;
}

.modification-items span {
    width: 20px;
    height: 20px;
    position: absolute;
    cursor: pointer;
}
.modification-items span.delete {    
    /* background-position: -58px 55px; */
    top: -2px;
}
.modification-items span.delete {
    /* background-position: -58px 55px; */
    top: 0;
    right: 0;
}

.modification-items span.edit {
    background-position: -58px 31px;
    top: 0;
    right: 26px;
}

.modification-items span.reply {
    background-position: -85px 29px;
    top: 0;
    right: 52px;
}

/* dashboard css */

.graph-area {
    z-index: 999;
    position: relative;
    margin-left: -18px;
    /* this will be remove once actual code will come. */
    margin-right: -18px;
    /*this will be remove once actual code will come. */
}

.graph-area img {
    max-width: 100%;
}

.income-chart-head-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    margin-bottom: 10px;
}

.income-chart-head-wrap .chart-head {
    color: #15264F;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
}

.income-chart-head-wrap .chart-month-select {
    color: #15264F;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
}

.income-chart-head-wrap .chart-month-select span {
    font-weight: 500;
}

.income-chart-head-wrap .dis-flex, .income-chart-head-wrap .filter-dropdown {
    margin-left: 20px;
}

.income-chart-rt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
}

.income-chart-head-wrap .custom-select {
    height: 40px;
    min-width: 106px;
}

.income-chart-head-wrap .select-selected {
    height: 40px;
    line-height: 22px;
}

.income-chart-head-wrap .select-selected:after {
    top: 14px;
}

.income-chart-head-wrap .dis-flex.min-134 {
    min-width: 134px;
}

.job-due-chart img {
    max-width: 100%;
}

.job-invoice-chart img {
    max-width: 100%;
}

.job-invoice-chart {
    margin-left: -15px;
    /* this will be remove once actual code will come. */
    margin-right: -15px;
    /* this will be remove once actual code will come. */
}

.job-due-chart {
    margin-left: -15px;
    /* this will be remove once actual code will come. */
    margin-right: -15px;
    /* this will be remove once actual code will come. */
}

/* subscription css */

.subscription-plan {
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #CBD1D5;
    padding: 30px;
    text-align: center;
    position: relative;
}

.subscription-plan:before {
    content: "";
    width: 100%;
    height: 6px;
    background: #B71B1B;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
}

.subscription-plan .subscription-head {
    font-size: 16px;
    color: #4c4c4c;
    text-transform: uppercase;
    margin: 0;
}

.subscription-plan .bill-text {
    font-size: 12px;
    color: #4c4c4c;
    line-height: 24px;
    margin: 0;
}

.subscription-plan ul {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.subscription-plan ul li {
    border-top: 1px solid rgba(151, 151, 151, .2);
    padding: 7px;
    min-height: 39px;
}

.subscription-plan ul li p {
    font-size: 14px;
    color: #4c4c4c;
    line-height: 24px;
    margin: 0;
}

.subscription-plan-rate {
    height: 78px;
    width: calc(100% + 60px);
    margin-left: -30px;
    background: rgba(235, 235, 235, .23);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
}

.subscription-plan-rate p {
    margin: 0;
    font-size: 40px;
    color: #B71B1B;
}

.rt-rate p {
    margin: 0;
    font-size: 40px;
    color: #4c4c4c;
}

.subscription-plan-rate p .dolr {
    font-size: 20px;
    color: #4c4c4c;
    font-weight: 500;
}

.subscription-lists ul li.active .rt-rate p {
    color: #B71B1B;
}

.rt-rate p .dolr {
    font-size: 20px;
    color: #4c4c4c;
    font-weight: 500;
}

.subscription-plan-rate p .per-month {
    font-size: 20px;
    color: #4c4c4c;
}

.rt-rate p .per-month {
    font-size: 20px;
    color: #4c4c4c;
}

.subscription-lists ul {
    padding-left: 0;
}

.subscription-lists ul li {
    border: 1px solid #CBD1D5;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    min-height: 96px;
    padding: 13px 24px;
    margin-bottom: 20px;
}

.subscription-lists ul li.active {
    border: 1.5px solid #B71B1B;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.subscription-lists .lt-check {
    padding-top: 10px;
    padding-right: 12px;
}

.subscription-lists .rt-rate {
    border-left: 1px solid rgba(151, 151, 151, .23);
    padding-left: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -wekit-flex;
    -webkit-align-items: center;
}

.subscription-lists .list-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
}

.subscription-lists .cust-checkbox {
    color: #4c4c4c;
}

.subscription-lists .cust-checkbox+p {
    color: #4c4c4c;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 0;
    margin-left: 29px;
}

.qr-code {
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    height: 111px;
    width: 111px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-justify-content: center;
}

.qr-wrap {
    width: 111px;
}

.qr-wrap p {
    text-align: center;
    margin-top: 10px;
}

.qr-code img {
    max-width: 100%;
    border-radius: inherit;
}

.company-logo-part {
    width: calc(100% - 95px);
}

.company-logo-part img {
    width: 100%;
}

.company-logo-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
}

button.btn-zoom.out.sprite-icon-before1:before {
    width: 30px;
    height: 34px;
    background-position: -98px -92px;
    position: absolute;
    top: 13px;
    left: 14px;
}

button.btn-zoom.out.sprite-icon-before1:hover:before {
    background-position: -138px -92px;
}

button.btn-zoom.in.sprite-icon-before1:before {
    width: 30px;
    height: 34px;
    background-position: -98px -132px;
    position: absolute;
    top: 13px;
    left: 14px;
}

button.btn-zoom.in.sprite-icon-before1:hover:before {
    background-position: -138px -132px;
}

button.btn-zoom.delete.sprite-icon-before1:before {
    width: 30px;
    height: 34px;
    background-position: -96px -173px;
    position: absolute;
    top: 13px;
    left: 14px;
}

button.btn-zoom.delete.sprite-icon-before1:hover:before {
    background-position: -131px -173px;
}

.company-logo-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
}

.company-logo-action button {
    margin-bottom: 28px;
}

.company-logo-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: calc(100% - 88px);
    margin-bottom: 24px;
}

.company-logo-btns .uploads-btn button {
    margin-left: 20px;
}

.report-search-result {
    margin-top: 12px;
}

.report-search-result .search-chip {
    margin-left: 16px;
}

.report-search-result .search-chip:first-child {
    margin-left: 0;
}

.elected-report {
    font-size: 10px;
}

/* setting css */

.settins-card {
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    padding: 30px 30px 0;
}

.settins-card .heading {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #4C4C4C;
    margin-bottom: 14px;
}

.settins-card p {
    font-size: 12px;
    line-height: 24px;
    color: #4C4C4C;
    margin-bottom: 0;
}

.settins-card ul {
    padding-left: 0;
    margin-bottom: 0;
}

.settins-card ul li {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, .2);
}

.settins-card ul li:last-child {
    border-bottom: none;
}

.setting-card-scroll-ht {
    height: 600px;
}

/* chat box css */

.people-list ul li .user-img {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    overflow: hidden;
}

.people-list ul li .user-img img {
    max-width: 100%;
}

.start-new-chat {
    background: #f2f2f2;
    height: 74px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding: 14px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
}

.new-chat.sprite-icon1 {
    width: 48px;
    height: 48px;
    background-position: -60px -274px;
    margin-right: 16px;
}

.start-new-chat p {
    margin-bottom: 0;
    color: #B71B1B;
    font-size: 15px;
}

.chat-list-btn {
    padding: 15px;
}

.chat-list-btn button {
    min-width: 140px;
    width: 140px;
    font-size: 14px;
    /* padding: 0 12px; */
    font-weight: 400;
}

.chat-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.people-list {
    width: 320px;
    min-width: 320px;
    background: #fff;
    margin-right: 16px;
    border: 1px solid #CBD1D5;
    border-radius: 4px;
}

.people-list .search {
    padding: 20px;
}

.people-list input {
    border-radius: 3px;
    border: none;
    padding: 14px;
    color: white;
    background: #6A6C75;
    width: 90%;
    font-size: 14px;
}

.people-list .fa-search {
    position: relative;
    left: -25px;
}

.people-list ul {
    padding: 0 15px 14px;
    height: auto;
    margin-bottom: 0;
}

.chat-history ul li {
    padding: 0 45px;
}

.message.other-message.float-right .chat-user-img {
    right: -45px;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 0;
    overflow: hidden;
}

.message.my-message .chat-user-img {
    position: absolute;
    left: -45px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 0;
    overflow: hidden;
}

.message.my-message .chat-user-img img, .message.other-message.float-right .chat-user-img img {
    max-width: 100%;
}

.chat .chat-history ul {
    padding-left: 0;
}

.people-list ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    height: 74px;
    border-bottom: 1px solid rgba(26, 26, 26, .1);
    position: relative;
}

.people-list ul li .time {
    font-size: 12px;
    position: absolute;
    right: 8px;
    top: 20px;
    color: #CECECE;
}

.people-list ul li.active .time {
    color: #fff;
    font-weight: 500;
    right: 25px;
}

.people-list img {
    float: left;
}

.people-list .about {
    float: left;
    margin-top: 8px;
}

.people-list .about {
    padding-left: 16px;
}

.people-list .status {
    color: #92959E;
}

.people-list .about .name {
    color: #4c4c4c;
    font-size: 14px;
}

.people-list ul li.active {
    width: calc(100% + 30px);
    background: #B71B1B;
    margin-left: -15px;
    padding: 15px 14px;
}

.people-list li.active .about .name {
    color: #fff;
    font-weight: 500;
}

.people-list li.active .status p {
    color: #fff;
    font-weight: 500;
}

.people-list .status p {
    margin: 0;
    font-size: 12px;
    color: #9C9A9A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 154px;
}

.people-list .notification .about .name, .people-list .notification .status p {
    font-weight: 500;
    color: #4c4c4c;
}

.people-list ul li.notification .time {
    color: #4c4c4c;
}

.notification .notify {
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    background: #B71B1B;
    border-radius: 50%;
    -webkit-box-shadow: inset 0 -1px 0 0 #EFEFEF;
            box-shadow: inset 0 -1px 0 0 #EFEFEF;
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    line-height: 10px;
    bottom: 14px;
    left: 26px;
}

.chat-header .user-img {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}

.chat-header .user-img img {
    width: 100%;
}

.chat {
    width: auto;
    background: #fff;
    border-radius: 4px;
    color: #434651;
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    height: 500px;
}

.chat .chat-header {
    margin: 15px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 15px;
    position: relative;
}

.chat .chat-header img {
    float: left;
}

.chat .chat-header .chat-about {
    padding-left: 10px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
        align-self: center;
}

.chat .chat-header .chat-with {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
}

.speaker-icon.sprite-icon1 {
    width: 20px;
    height: 20px;
    background-position: -128px -331px;
    position: absolute;
    right: 4px;
    top: 8px;
    cursor: pointer;
}

.chat .chat-header .chat-num-messages {
    color: #92959E;
}

.chat .chat-header .fa-star {
    float: right;
    color: #D8DADF;
    font-size: 20px;
    margin-top: 12px;
}

.chat .chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    /* overflow-y: scroll; */
}

.chat .chat-history-wrap {
    height: 334px;
}

.chat .chat-history .message-data {
    margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
    color: #9C9A9A;
    font-size: 12px;
}

.chat .chat-history .message {
    padding: 15px 17px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 0px;
    line-height: 19px;
    width: auto;
    position: relative;
    color: #fff;
    display: inline-block;
}

.message-data1 {
    position: absolute;
    bottom: -19px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    left: 0;
}

.message-data1 .seen-tick {
    color: #B71B1B;
    font-size: 12px;
    font-weight: 500;
    padding-right: 30px;
}

.seen-tick.sprite-icon-before:before {
    width: 27px;
    height: 14px;
    background-position: 32px -458px;
    position: absolute;
    right: 0;
    top: 2px;
}

.typing-icon.sprite-icon-before1 {
    position: relative;
    min-width: 76px;
    padding: 3px 0
}

.typing-icon.sprite-icon-before1:before {
    width: 34px;
    height: 12px;
    background-position: -121px -288px;
    position: absolute;
    top: 0px;
}

.chat .chat-history .message p {
    margin-bottom: 0;
}

.chat .chat-history .message.my-message {
    color: #4c4c4c;
}

.chat .chat-history .message.my-message:after {
    bottom: 100%;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #f2f2f2;
    border-width: 10px;
    margin-left: -8px;
    top: 0;
}

.chat .chat-history .message.other-message:after {
    bottom: 100%;
    left: auto;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #B71B1B;
    border-width: 10px;
    margin-left: -8px;
    top: 0;
    right: -8px;
}

.chat .chat-history .my-message {
    background: #F2F2F2;
}

.chat .chat-history .other-message {
    background: #B71B1B;
    margin-bottom: 35px;
}

.chat .chat-history .other-message:after {
    border-bottom-color: #94C2ED;
    left: 93%;
}

.chat .chat-message {
    padding: 0;
    border-top: 1px solid #CBD1D5;
    margin-top: 40px;
    position: relative;
}

.chat .chat-message textarea {
    width: 100%;
    border: none;
    padding: 18px 19px 0;
    margin-bottom: 0;
    border-radius: 5px;
    resize: none;
    height: 55px;
    outline: none;
}

textarea.message-to-send::-webkit-input-placeholder {
    color: #8B8B8B;
    font-size: 12px;
}

textarea.message-to-send::-moz-placeholder {
    /* Firefox 19+ */
    color: #8B8B8B;
    font-size: 12px;
}

textarea.message-to-send:-ms-input-placeholder {
    color: #8B8B8B;
    font-size: 12px;
}

textarea.message-to-send::-ms-input-placeholder {
    color: #8B8B8B;
    font-size: 12px;
}

textarea.message-to-send::placeholder {
    color: #8B8B8B;
    font-size: 12px;
}

.chat .chat-message .fa-file-o, .chat .chat-message .fa-file-image-o {
    font-size: 16px;
    color: gray;
    cursor: pointer;
}

button.send-btn.sprite-icon1 {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 10px;
    right: 20px;
    /* background-position: -15px -275px; */
    border: none;
    background: transparent;
    outline: none;
}
button.send-btn.sprite-icon1:before {
    content: "";
    width: 36px;
    height: 36px;
    background: #b71b1b;
    background-image: url(img/send-vr-2.png);
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 58%;
    border-radius: 50%;
}

.attached-btn.sprite-icon {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 8px;
    right: 76px;
    background-position: 39px -497px;
    border: none;
}

/* .chat .chat-message button {
    float: right;
    color: #94C2ED;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #F2F5F8;
  }
  .chat .chat-message button:hover {
    color: #75b1e8;
  } */

.online, .offline, .me {
    margin-right: 3px;
    font-size: 10px;
}

.online {
    color: #86BB71;
}

.offline {
    color: #E38968;
}

.me {
    color: #94C2ED;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.date-filter {
    width: 51px;
    height: 40px;
    border-radius: 4px;
    background: #fff;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.date-filter.sprite-icon-before:before {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 10px;
    left: 15px;
    background-position: -32px -445px;
    background: url(img/calender-vr-2.png);
    background-repeat: no-repeat;
}

/* end of chat box css */

.report-container .report-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 132px);
}

/* new report screen css */

.report-section {
    border: 2px solid #CBD1D5;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    background-color: #FFFFFF;
    padding: 30px;
}

.report-inner-part, .report-inner-group-part {
    border: 1px solid #CBD1D5;
    /* box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24); */
    background-color: #FFFFFF;
    padding: 24px;
    margin-bottom: 15px;
}

.btn-rt-wrap {
    text-align: right;
}

.user-name-add-inn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

/* .user-name-add-inn .user-name-fld, .user-name-add-inn .user-address-fld{
    flex:1;
} */

.user-name-add-inn .user-name-fld {
    width: 55%;
}

.user-name-add-inn .user-address-fld {
    width: 45%;
}

.user-address-fld-inn .ofc-no, .user-address-fld-inn .ofc-addr {
    position: relative;
}

.user-address-fld-inn .ofc-no.sprite-icon-before:before {
    width: 12px;
    height: 18px;
    background-position: 52px -456px;
    position: absolute;
    left: -20px;
    z-index: 0;
}

.user-address-fld-inn .ofc-addr.sprite-icon-before:before {
    width: 17px;
    height: 18px;
    background-position: 30px -479px;
    position: absolute;
    left: -23px;
    z-index: 0;
}

.user-name-fld .user-img {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    background-color: #E4E5E6;
    border-radius: 50%;
    margin-right: 20px;
    overflow: hidden;
}

.user-name-fld .user-img img {
    max-width: 100%;
    width: 100%;
}

.work-list-icon .frequency-drop {
    position: absolute;
    right: 10px;
    top: -16px;
    width: 140px;
}

.frequency-drop .select-selected {
    height: 30px;
    padding: 0px 16px;
    font-size: 12px;
}

.frequency-drop .custom-select+label {
    /* font-size: 10px; */
    margin-bottom: 2px;
}

.frequency-drop .custom-select {
    height: 30px;
}

.frequency-drop .select-selected:after {
    top: 10px;
    background-position: -77px 573px;
    right: 10px;
}

.frequency-drop .select-selected.select-arrow-active:after {
    top: 10px;
    background-position: -97px 573px;
    right: 10px;
}

.user-name-fld {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-align-items: center;
}

.user-name-fld .user-name p {
    font-size: 24px;
    color: #000;
    line-height: 28px;
}

.user-name-fld .user-house {
    width: 24px;
    min-width: 24px;
    height: 24px;
    position: relative;
}

.user-name-fld .user-house:before {
    /* width: 24px;
    height: 24px;
    background-position: -16px -16px; */
    width: 28px;
    height: 28px;
    background-position: -199px -60px;
    position: absolute;
}

.user-address-fld {
    position: relative;
    padding-left: 20px;
}

.user-address-fld:before {
    content: "";
    width: 2px;
    height: 100%;
    height: -webkit-fill-available;
    /* background: #979797; */
    position: absolute;
    border-left: 2px dashed #979797;
    left: -32px;
    max-height: 100%;
}

.user-address-fld-inn .ofc-no p, .user-address-fld-inn .ofc-cell-no p, .user-address-fld-inn .ofc-addr p {
    margin-bottom: 0;
    color: #8E8E8E;
}

.user-address-fld-inn .ofc-no p span, .user-address-fld-inn .ofc-cell-no p span, .user-address-fld-inn .ofc-addr p span {
    color: #000000;
    padding-left: 6px;
}

.ofc-cell-no {
    margin: 16px 0;
}

.address-icon.sprite-icon1 {
    position: absolute;
    width: 28px;
    height: 28px;
    right: -10px;
    bottom: 0;
    background-position: -200px -262px;
}

.house-list-wrap {
    display: none;
}

.house-list {
    width: 192px;
    background: #fff;
    margin-top: 13px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
}

.user-name-fld .user-house:hover .house-list-wrap {
    display: block;
    position: absolute;
    top: 20px;
    z-index: 9;
}

.house-list ul {
    padding-left: 0;
    margin-bottom: 0;
}

.house-list ul li {
    border-bottom: 1px solid #f2f2f2;
}

.house-list ul li:last-child {
    border-bottom: none;
}

.house-list ul li:hover {
    background: #f2f2f2;
}

.house-list ul li:hover a {
    color: #4c4c4c;
    font-weight: 500;
}

.house-list ul li a {
    line-height: 48px;
    display: block;
    height: 48px;
    padding: 0 25px;
    font-size: 14px;
    color: #828282;
    text-decoration: none;
}

.address-icon {
    position: relative;
}

.address-icon:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -4px;
    left: -5px;
}

.address-icon:hover:after {
    content: "Map";
    width: 52px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
    z-index: 9;
}

#get-start p, #get-start2 p {
    margin: 0;
}

.no-mrg {
    margin: 0;
}

/* vertical pannel wrap */

.vertical-pannel .vertical-pannel-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.vertical-pannel-wrap .left-wrap-panel {
    width: 175px;
}

.vertical-pannel-wrap .right-wrap-panel {
    width: calc(100% - 175px);
    border: 2px solid #CBD1D5;
    background: #fff;
    padding: 30px;
    border-radius: 0 4px 4px 4px;
    min-height: 500px;
}

.left-wrap-panel ul li, .left-wrap-panel ul.nav-tabs li {
    height: 56px;
    margin-bottom: 10px;
}

.left-wrap-panel ul li a, .left-wrap-panel ul.nav-tabs li a, .left-wrap-panel ul.nav-tabs li a:hover {
    height: 56px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #B71B1B;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    color: #4c4c4c;
    font-size: 14px;
    line-height: 16px;
    margin-right: 12px;
}

.left-wrap-panel ul.nav-pills li a.nav-link {
    border-radius: 4px 0 0 4px;
}

.left-wrap-panel ul.nav-pills li a.nav-link.active {
    background: #fff;
    margin-right: -2px;
    color: #B71B1B;
    font-weight: 500;
    font-size: 16px;
    border-right: 0px;
    z-index: 1;
    position: relative;
    border: 2px solid #CBD1D5;
    border-right: 0;
}

.vertical-pannel-wrap {
    margin-top: 20px;
}

.report-inner-part input.form-control, .report-inner-part textarea.form-control {
    margin-bottom: 0;
}

.report-inner-part .form-group.dis-flex.f-dir-column {
    margin-bottom: 0;
}

.work-list-icon .list-icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.user-name-fld .user-name {
    width: 100%;
}

.work-list-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-justify-content: space-between;
    width: calc(100% - 30px);
    position: relative;
}

.work-list-icon .expand-icon {
    width: 20px;
    height: 20px;
}

.expand-icon.sprite-icon1 {
    width: 28px;
    min-width: 28px;
    height: 28px;
    background-position: -198px -110px;
    position: relative;
}

.imag-upload.sprite-icon1 {
    width: 24px;
    height: 24px;
    background-position: -16px -104px;
    position: relative;
}

.note.sprite-icon1 {
    width: 24px;
    height: 24px;
    background-position: -16px -148px;
    position: relative;
}

.work-list-icon .imag-upload {
    width: 28px;
    /* min-width: 28px; */
    height: 28px;
    background-position: -201px -163px;
}

.work-list-icon .note {
    width: 28px;
    min-width: 28px;
    height: 28px;
    background-position: -201px -212px;
}

.work-list-icon>div {
    margin-right: 15px;
    position: relative;
}

.list-icon-wrap>div {
    margin-right: 15px;
}

.work-list-icon .imag-upload:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -4px;
    left: -5px;
}

.work-list-icon .imag-upload:hover:after {
    content: "Image";
    width: 74px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.work-list-icon .note:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -4px;
    left: -5px;
}

.work-list-icon .note:hover:after {
    content: "Add Note";
    width: 74px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

.right-wrap-panel .read-more {
    margin-top: -18px;
}

.tab-text-head {
    font-size: 16px;
    color: #B71B1B;
    line-height: 19px;
}

hr.seperator {
    border-top: 1px dashed #979797;
    margin-top: 30px;
    margin-bottom: 30px;
}

.signature-btn-action button.btn.btn_dark {
    max-width: 82px;
    min-width: 82px;
    margin-right: 10px;
}

.signature-btn-action button.btn.btn_close {
    max-width: 82px;
    min-width: 82px;
    margin-right: 10px;
}

.signature-btn-action button.btn.btn_success {
    min-width: 82px;
    max-width: 82px;
}

.signature-btn-action {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.signature-sec {
    position: relative;
    width: 100%;
}

.head-seperation .seperate-line {
    padding: 0 22px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-lign-items: center;
    -webkit-align-self: center;
}

/* vertical icons starts*/

.vertical-bar {
    background: transparent;
    position: fixed;
    bottom: 10px;
    right: 0;
    z-index: 1000;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

/* vertical icons ends */

.monitor-radio label {
    margin-right: 90px;
}

.monitor-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-display: flex;
    margin-top: 36px;
}

.monitor-notes {}

.report-inner-part {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    position: relative;
}

.hide.sprite-icon-before:before {
    width: 26px;
    height: 20px;
    background-position: 109px -533px;
    left: -21px;
    top: -2px;
    position: absolute;
}

.Notes.sprite-icon-before:before {
    width: 26px;
    height: 20px;
    background-position: 150px -296px;
    left: -16px;
    top: 0px;
    position: absolute;
}

.hide.sprite-icon-before, .Notes.sprite-icon-before {
    padding-left: 10px;
    margin-left: 15px;
    margin-right: 20px;
}

.report-inner-part .dis-flex {
    width: 100%;
}

.Na .cust-checkbox {
    color: #000;
}

.option-check {
    position: absolute;
    right: 24px;
    top: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.option-check1 {
    position: absolute;
    right: 18px;
    top: -2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.option-check1 .info-icon.sprite-icon-before:before {
    background-position: -58px -481px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.note-edit.sprite-icon-before:before {
    background-position: 144px -296px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -20px;
    cursor: pointer;
}

.info-icon.sprite-icon-before:before {
    background-position: -58px -481px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: -24px;
    cursor: pointer;
}

.note-edit.sprite-icon-before {
    margin-left: 40px;
}

.option-check .cust-checkbox {
    color: #000;
}

.left-lable-gp .custom-select+label {
    padding-right: 100px;
}

.superviion-date {
    border: 1px solid #CBD1D5;
    background-color: #FFFFFF;
    padding: 24px;
    margin-bottom: 15px;
    position: relative;
}

.dlt-save-btn button:first-child {
    margin-right: 18px;
}

.btn-group-section {
    margin-top: 20px;
}

.accordian2 .texted {
    position: absolute;
    top: -15px;
    right: -110px;
    color: #6AA14E;
    font-weight: 500;
}

.accordian2 .texted:after {
    content: "";
    width: 2px;
    height: 20px;
    background: #979797;
    position: absolute;
    left: -44px;
    top: 12px;
}

.accordian2 .texted:before {
    position: absolute;
    width: 20px;
    height: 20px;
}

.accordian2 .texted.sprite-icon-before:before {
    width: 22px;
    height: 22px;
    position: absolute;
    left: -26px;
    top: 11px;
    background-position: 51px -90px;
}

.accordian2 .deleted.sprite-icon-before {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 8px;
    cursor: pointer;
}

.accordian2 .deleted.sprite-icon-before:before {
    width: 20px;
    height: 20px;
    background-position: -18px -310px;
    position: absolute;
}

.device-tab-text {
    max-width: 420px;
    margin-bottom: 4px;
}

label .note-edit.sprite-icon-before {
    position: absolute;
    right: 0;
    top: 0;
}

/* inventory css */

.parts-group-btn button:first-child {
    margin-right: 12px;
}

.parts-group-btn+.filter-row>button:first-child {
    margin-right: 20px;
}

span.table-image {
    width: 48px;
    height: 48px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    top: 2px;
}

span.table-image img {
    max-width: 100%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:first-child {
    width: 10%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 12%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 15%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 16%;
}

.parts-table.global-table .table tbody tr td table tbody tr td:nth-child(7) {
    width: 15%;
}

.parts-table.global-table .table thead tr th:first-child {
    width: 10%;
}

.parts-table.global-table .table thead tr th:nth-child(2) {
    width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(3) {
    width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(4) {
    width: 12%;
}

.parts-table.global-table .table thead tr th:nth-child(5) {
    width: 15%;
}

.parts-table.global-table .table thead tr th:nth-child(6) {
    width: 16%;
}

.parts-table.global-table .table thead tr th:nth-child(7) {
    width: 15%;
}

.in-stock {
    color: #6AA14E;
    font-size: 14px;
    font-weight: 500;
}

.service {
    color: #1786FE;
    font-size: 14px;
    font-weight: 500;
}

.AddedStock {
    color: #E76C04;
    font-size: 14px;
    font-weight: 500;
}

.out-stock {
    color: #B71B1B;
    font-size: 14px;
    font-weight: 500;
}

.info-head {
    font-size: 16px;
    font-weight: 500;
    color: #4c4c4c;
    margin-bottom: 20px;
}

span.info-tooltip {
    width: 98px;
    display: inline-block;
    padding: 7px;
    font-size: 12px;
    line-height: 12px;
    background: #4C4C4C;
    border-radius: 3px;
    color: #fff;
    position: absolute;
    top: -46px;
    right: 0;
}

span.info-tooltip:before {
    content: "";
    position: absolute;
    border-top: 8px solid #4c4c4c;
    border-left: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 38px;
    border-right: 4px solid transparent;
    right: 8px;
}

.custom-select.select-drop-btn {
    min-width: 152px;
}

.custom-select.select-drop-btn .select-selected {
    border: 1.5px solid #6AA14E;
    color: #6aa14e;
    text-align: center;
}

span.date-of-create {
    font-weight: normal;
}

span.date-of-create span {
    font-weight: 500;
}

.tale-part-search p {
    margin: 0;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-align-self: center;
    position: relative;
}

.expand.sprite-icon-before {
    position: static;
}

.expand.sprite-icon-before:before {
    height: 20px;
    width: 20px;
    position: absolute;
    background-position: 53px -239px;
    top: 0;
    right: -30px;
}

.expand.srite-icon-before:before {
    width: 20px;
    height: 20px;
    position: absolute;
    background-position: 0 0;
    content: "";
}

.part-info-icon {
    position: relative;
    display: inline-block;
}

.info-part-icon.sprite-icon-before:before {
    height: 20px;
    width: 20px;
    position: absolute;
    background-position: -58px -481px;
    top: 0;
    right: -30px;
    cursor: pointer;
}

.info-part-icon.sprite-icon-before {
    position: static;
}

.global-sm-table button.clone.btn {
    -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
            box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.13);
    background-color: #FFFFFF;
    border-radius: 4px;
    min-height: 20px;
    height: 20px;
    border: 1px solid #6AA14E;
    width: auto;
    min-width: auto;
    font-weight: normal;
    color: #6AA14E;
    padding: 0px 10px;
    font-size: 12px;
}

.tab-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding-left: 0;
    margin: 0;
    width: 84%;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.tab-list ul li:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid #CBD1D5;
}

.tab-list ul li:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #CBD1D5;
}

.tab-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
}

.tab-list button {
    padding: 0 15px;
    min-width: auto;
}

.tab-list ul li {
    padding: 10px 16px;
    border: 1px solid #CBD1D5;
    border-right: 0;
    border-left: 0;
    position: relative;
    height: 40px;
    /* min-width: 120px; */
    font-size: 14px;
    text-align: center;
    color: #4C4C4C;
    background: #fff;
    white-space: nowrap;
}

.tab-list ul li:before {
    width: 1px;
    height: 27px;
    background: #CBD1D5;
    position: absolute;
    right: -1px;
    top: 6px;
    content: "";
    z-index: 1;
}

.tab-list ul li.active:before {
    width: 0;
}

.tab-list ul li:last-child:before {
    width: 0;
}

.tab-list ul li.active {
    background: #B71B1B;
    color: #fff;
    font-weight: 500;
    border: 1px solid #B71B1B;
    z-index: 9;
}

.service-due-list ul {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.service-due-list ul li:nth-child(3n + 0) {
    margin-right: 0;
    width: calc(33.333% - 0px);
}

.service-due-list ul li {
    border: 1px solid #CBD1D5;
    color: #4C4C4C;
    background: #fff;
    height: 55px;
    width: 237px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-flex;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    width: calc(33.333% - 15px);
    text-align: center;
}

.service-due-list ul li p {
    margin: 0;
}

.service-due-list ul li p span {
    font-weight: 500;
    padding-left: 6px;
    display: inline-block;
}

.service-due-section>p {
    font-size: 12px;
    color: #4c4c4c;
    font-weight: 500;
}

.due-service-for p {
    margin: 0;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-align-self: center;
    color: #4c4c4c;
    font-size: 12px;
    font-weight: 500;
}

lable.img-label {
    color: rgba(76, 76, 76, .76);
    font-size: 14px;
    padding-bottom: 8px;
    display: inline-block;
}

.btn-rt-align {
    text-align: right;
}

.btn-rt-align button {
    margin-left: 20px;
}

.global-table.quote-table .table tbody tr td table tbody tr td:last-child {
    text-align: left;
    padding-left: 20px;
}

.global-table.quote-table .table thead tr th:last-child {
    text-align: left;
}

.total-part-labor {
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    height: 30px;
    display: inline-block;
}

.total-part-labor .total-text {
    background: #B71B1B;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 16px;
    line-height: 30px;
    height: 30px;
    border-radius: 4px 0 0 4px;
    color: #fff;
}

.total-part-labor .totap-rice {
    font-size: 14px;
    color: #4c4c4c;
    padding: 5px 40px;
    text-align: center;
}

span.drive.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 24px;
    background-position: -456px -472px;
    left: -31px;
}

span.break.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 22px;
    background-position: -487px -472px;
    left: -31px;
}

span.working.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 24px;
    background-position: -457px -503px;
    left: -31px;
}

span.break.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 22px;
    background-position: -487px -472px;
    left: -31px;
}

span.checkout.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 24px;
    background-position: -488px -503px;
    left: -31px;
}

span.miscellaneous.sprite-icon-before:before {
    position: absolute;
    width: 24px;
    height: 22px;
    background-position: -487px -472px;
    left: -31px;
}

span.working {
    color: #41BA1A;
    font-weight: 500;
}

span.checkout {
    color: #ABABAB;
    font-weight: 500;
}

span.miscellaneous {
    color: #F4CF49;
    font-weight: 500;
}

span.break {
    color: #B71B1B;
    font-weight: 500;
}

span.drive {
    color: #4DBCE9;
    font-weight: 500;
}

span.status-arrow:after {
    content: "";
    position: absolute;
    background-size: 600px;
    background-position: -76px -27px;
    width: 12px;
    height: 12px;
    top: 2px;
    right: -16px;
}

.time-icon:hover:before {
    content: "";
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: -7px;
}

.time-icon:hover:after {
    content: "Time";
    width: 66px;
    height: 25px;
    background: #4C4C4C;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    top: 36px;
    left: -29px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    border-radius: 2px;
}

/* team card css */

.team-card .team-card-head {
    height: 68px;
    padding: 25px 30px;
    background-color: #292C36;
}

.team-card .team-card-head p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.team-card .team-card-body {
    border: 1px solid #4c4c4c;
}

.team-card-inn-body>ul>li {
    position: relative;
    padding-left: 80px;
    padding-top: 15px;
    padding-bottom: 40px;
}

.team-card-inn-body.force-overflow-edit-team>ul>li {
    padding-top: 6px;
}

.team-card-inn-body>ul>li.active-checked {
    padding-bottom: 6px;
}

.team-card-inn-body>ul>li:last-child {
    padding-bottom: 0;
}

.team-card-inn-body>ul>li:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 25px;
    background: #4c4c4c;
}

.team-card-inn-body>ul>li:last-child:before {
    width: 0;
}

.team-card-inn-body>ul>li.active-checked:before {
    background: #B71B1B;
    width: 2px;
}

.team-card-inn-body ul>li .steps {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    border: 1px solid #4c4c4c;
    border-radius: 50%;
    color: #4c4c4c;
    font-size: 24px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: 0;
    top: 0;
}

.team-card-inn-body ul>li.active .steps {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #B71B1B;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: 0;
    top: 0;
    border: none;
}

.team-card-inn-body ul>li.active-checked .steps {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 50%;
    color: #B71B1B;
    font-size: 24px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    left: 0;
    top: 0;
    border: 1.5px solid #B71B1B;
}

.team-card-inn-body {
    padding: 30px;
    background: #fff;
}

.team-card-inn-body ul {
    padding-left: 0;
    margin-bottom: 0;
}

.team-card-inn-body .step-head {
    font-size: 16px;
    font-weight: 500;
    color: #B71B1B;
    line-height: 19px;
    margin-bottom: 30px;
}

.team-card-inn-body hr.seperator {
    border-top: 1px dashed #979797;
    margin-top: 30px;
    margin-bottom: 30px;
    position: absolute;
    bottom: 10px;
    left: 80px;
    width: calc(100% - 80px);
    padding: 0;
    margin: 0;
    ;
    display: none;
}

.line-ht label {
    line-height: 18px;
}

.team-card-inn-body .c-checkboc {
    position: absolute;
    right: 0;
}

.step-checks ul {
    padding-left: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
}

.step-checks ul li {
    width: 50%;
    margin-bottom: 30px;
}

.week-date {
    position: absolute;
    right: 0;
    top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-flex;
    -webkit-align-items: center;
}

.week-date>span {
    padding-right: 12px;
}

.date-show-feild {
    border: 1px solid #CBD1D5;
    height: 30px;
    background: #f2f2f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 16px;
    color: #4c4c4c;
    padding: 5px 75px 5px 15px;
    border-radius: 4px;
}

.date-show-feild.sprite-icon-before:before {
    position: absolute;
    background-position: -30px -443px;
    width: 25px;
    height: 24px;
    right: 10px;
    cursor: pointer;
}

.card-table-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.card-table-head .table-th {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: center;
    -webkit-flex: 1;
    -webkit-text-align: center;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-align-self: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    border-bottom: 1px solid #4c4c4c;
    border-right: 1px solid #4c4c4c;
}

.card-table-head .table-th:last-child {
    border-right: 0;
}

.time-card-table {
    border: 1px solid #4c4c4c;
    border-radius: 4px;
}

.card-tbody-tr .table-td {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: center;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-left: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    color: #4c4c4c;
    position: relative;
}

.card-tbody-tr:last-child .table-td {
    border-bottom: none;
}

.card-tbody-tr .table-td:first-child {
    border-left: 0;
}

.card-tbody-tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.table-td.red-td-bg {
    background: #B71B1B;
    color: #fff;
}

.gryth-bg {
    background: rgba(239, 239, 239, .5);
    font-weight: 500;
}

.card-table-head .table-th:first-child {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-justify-content: flex-start;
    padding-left: 15px;
}

.card-tbody-tr .table-td:first-child {
    padding-left: 15px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-justify-content: flex-start;
}

.daytask1 {
    font-weight: 500;
}

.Driving1 {
    color: #4DBCE9;
    font-weight: 500;
}

.break1 {
    color: #B71B1B;
    font-weight: 500;
}

.workin1 {
    color: #41BA1A;
    font-weight: 500;
}

.checkout1 {
    color: #ABABAB;
    font-weight: 500;
}

.miscellaneous1 {
    color: #F4CF49;
    font-weight: 500;
}

.emplyee-list-rt {
    position: fixed;
    right: 0;
    top: 64px;
    width: 525px;
}

.emplyee-list-rt .team-card .team-card-head {
    height: 40px;
    padding: 10px 20px;
}

.emplyee-list-rt .team-card-inn-body {
    padding: 20px;
    background: #fff;
}

.team-card-body .global-table .table tbody tr td table tbody tr td {
    width: 34%;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:last-child {
    width: 10%;
}

.team-card-body .global-table .table thead tr th {
    width: 34%;
}

.team-card-body .global-table .table thead tr th:nth-child(2) {
    width: 26%;
}

.team-card-body .global-table .table thead tr th:last-child {
    width: 10%;
}

.team-card-body .global-table .table thead tr th:first-child {
    padding-left: 0px;
    text-align: left;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:first-child {
    padding-left: 12px;
    text-align: left;
}

.team-card-body .global-sm-table .update-icon {
    background-size: 600px;
    background-position: -445px 31px;
    right: 18px;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
}

.team-card-body .global-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 26%;
}

.emplyee-list-rt .team-card {
    border-radius: 3px;
    overflow: hidden;
}

.time-card-section .week-date {
    top: -12px;
}

p.time-card.sprite-icon-before:before {
    background-position: -60px -483px;
    position: absolute;
    width: 16px;
    height: 16px;
    right: -24px;
    top: 2px;
}

.cus-lg-table .card-table-head .table-th {
    height: 44px;
}

.cus-lg-table .card-tbody-tr .table-td {
    height: 48px;
}

.cus-lg-table.time-card-table {
    border: 1px solid #4c4c4c;
    border-radius: 3px;
    border-bottom-right-radius: 0;
    background: #fff;
}

.table-td.gryth-bg.total-amnt {
    position: absolute;
    width: 101%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 48px;
    top: 48px;
    background-color: #D8D8D8;
    border: 1px solid #4c4c4c;
    border-top: none;
    border-radius: 0 0 3px 3px;
    right: -1px;
}

.custom-select.bg-select-w {
    background: #fff;
}

.bg-select-w .select-selected {
    background: #fff;
}

/* quaterly modal css */

.quaterly-wrap-modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.quaterly-wrap-modal .quaterly-left, .quaterly-wrap-modal .quaterly-right {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
    padding-left: 24px;
}

.job-id-no {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.job-id-no input {
    margin-left: 12px;
    border: 1px solid #CBD1D5;
    border-radius: 4px;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 30px;
    padding: 0 16px;
    width: calc(100% - 176px);
    background: #f2f2f2f2;
    outline: none;
}

.quaterly-right {
    position: relative;
}

.quaterly-right:before {
    content: "";
    width: 2px;
    height: 120px;
    /* background: #979797; */
    position: absolute;
    border-left: 1px dashed #979797;
    top: -10px;
    left: -32px;
}

.quaterly-right .ofc-no.sprite-icon-before {
    margin-bottom: 14px;
}

.quaterly-left .user-address-fld-inn .user-icon1.ofc-no.sprite-icon-before:before {
    width: 12px;
    height: 18px;
    background-position: 52px -431px;
    position: absolute;
    left: -20px;
}

.job-id-no {
    margin: 14px 0;
    margin-left: -18px;
}

.job-id-no p {
    margin: 0;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-align-self: center;
}

#job-link hr.seperator {
    border-top: 2px dashed #c4c4c4;
    margin-top: 30px;
    margin-bottom: 30px;
    width: calc(100% + 48px);
    margin-left: -24px;
}

.job-link-date-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.job-link-date-wrap .job-link-date-left, .job-link-date-wrap .job-link-date-right {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-flex: 1;
}

.job-link-date-wrap .job-link-date-left {
    margin-right: 40px;
}

p.date-head {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.select-time-wrap>div {
    margin-right: 10px;
    min-width: 100px;
}

.select-time-wrap>div:last-child {
    margin-right: 0;
}

.start-time-head {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-top: 20px;
}

.select-time-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
}

.small-text-msg {
    color: #4C4C4C;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    margin-top: 4px;
}

.search-chip .cust-checkbox {
    color: #4DBCE9;
}

.search-chip .cust-checkbox .checkmark {
    top: 4px;
}

.search-chip .cust-checkbox input:checked~.checkmark {
    top: 4px;
}

.check-clips-wrap .search-chip {
    margin-right: 5px;
}

.job-id-note {
    height: 60px;
    background: #f5f5f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    padding-left: 24px;
    width: calc(100% + 48px);
    margin-left: -24px;
}

.job-id-note p {
    color: #000;
    font-size: 16px;
    /* text-transform: uppercase; */
    margin: 0;
    -ms-flex-item-align: center;
        align-self: center;
    -webkit-align-self: center;
    font-weight: 500;
}

.job-id-note p.sprite-icon-before:before {
    width: 20px;
    height: 20px;
    background-position: -77px 556px;
    position: absolute;
    right: -30px;
    top: 3px;
}

.quaterly-wrap-modal .quaterly-right {
    position: relative;
}

.quaterly-wrap-modal .address-icon.sprite-icon1 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    bottom: auto;
    background-position: -148px -15px;
    top: 55px;
}

p.email-id-icon.sprite-icon-before:before {
    width: 20px;
    height: 20px;
    background-position: -32px -474px;
    position: absolute;
    right: 64px;
    top: -2px;
    cursor: pointer;
}

.vertical-pannel .nav-tabs {
    display: none;
}

.vertical-pannel .card .card-header2+.collapse {
    display: none;
}

.vertical-pannel .card .card-header2+.collapse.show {
    display: block;
}

.filter-btn-grp .select-selected {
    background: #fff;
}

@media (max-width: 1365px) {
    span.pdf-icon.sprite-icon {
        right: 15px;
    }
    span.word-icon.sprite-icon {
        right: 50px;
    }
    span.note-icon.sprite-icon {
        right: 90px;
    }
    /* .tab-list ul li{
        min-width: 92px;
    } */
}

@media (min-width: 1366px) {
    .analytic-boxes .analytic-box {
        padding-left: 40px;
    }
    .analytic-boxes .analytic-no {
        padding-right: 30px;
    }
    .analytic-boxes .analytic-text {
        max-width: 130px;
        z-index: 9;
    }
    .admin-table.global-table .table tbody tr td table tbody tr td:nth-child(5) {
        padding-left: 54px;
    }
}

@media (min-width: 1560px) {
    .analytic-boxes .analytic-text {
        max-width: 170px;
    }
}

@media (max-width: 1200px) {
    .customer-detail-modal {
        width: 100%;
    }
    .video-section .video-play-area {
        min-height: 100%;
    }
    .responsive-mob .search-container button {
        width: 40px;
    }
    .responsive-mob .search-container input {
        min-width: 194px;
    }
    .responsive-mob .search-container button .search-icon {
        left: 10px;
    }
    .user-name-fld {
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-align-items: flex-start;
    }
    .work-list-icon {
        display: block;
    }
    .work-list-icon .frequency-drop {
        position: relative;
        width: 140px;
        display: block;
        margin-top: 24px;
        margin-left: 12px;
    }
    .report-inner-part {
        display: block;
    }
    .monitor-notes {
        /* margin-top: 16px; */
    }
}

/* @media (max-width: 991px){
    
} */

@media (min-width: 768px) {
    .vertical-pannel .nav-tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .vertical-pannel .card {
        border: none;
    }
    .vertical-pannel .card .card-header1, .vertical-pannel .card .card-header {
        display: none;
    }
    .vertical-pannel .card .collapse {
        display: block;
    }
}

@media (max-width: 767px) {
    #sidebar .collapse-lists a.list-group-item.d-inline-block.collapsed.active-list.active-list {
        width: 300px;
    }
    .tab-list ul {
        width: 100%;
        margin-bottom: 16px;
    }
    .tab-list {
        display: block;
    }
    .table-responsive .showInMobile {
        display: block;
    }
    .table-responsive .showInDesktop {
        display: none;
    }
    .responsive-mob .filter-btn-grp .filter-row {
        margin-bottom: 0;
    }
    .responsive-mob .filter-btn-grp {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        display: -webkit-flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-align-items: center;
        margin-top: 16px;
    }
    .company-logo-btns .uploads-btn button {
        margin-left: 0;
        margin-top: 16px;
        width: 100%;
    }
    .company-logo-btns {
        display: block;
    }
    .preview-btn button {
        width: 100%;
    }
    .income-chart-rt {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }
    .income-chart-head-wrap .dis-flex, .income-chart-head-wrap .filter-dropdown {
        margin-left: 0;
        margin-right: 20px;
        margin-bottom: 16px;
    }
    .income-chart-head-wrap .chart-month-select {
        margin-right: 20px;
        margin-bottom: 16px;
    }
    .graph-area {
        z-index: 999;
        position: relative;
        margin-left: 0px;
        /* this will be remove once actual code will come. */
        margin-right: 0px;
        /*this will be remove once actual code will come. */
    }
    .main-container.container-dash-bg:before {
        height: 240px;
    }
    .mob-mrbt-20 {
        margin-bottom: 20px;
    }
    .team-card .week-date {
        position: relative;
        left: -60px;
        top: -20px;
    }
    .timecard-table-scroll {
        width: calc(100% + 60px);
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: -60px;
    }
    .timecard-table-scroll .time-card-table {
        width: 700px;
    }
    .emplyee-list-rt {
        width: 100%;
    }
    .subscription-plan {
        margin-bottom: 20px;
    }
    .settins-card {
        margin-bottom: 20px;
    }
    .settins-card.mr-top-30 {
        margin-top: 20px;
    }
    .report-section {
        padding: 15px;
    }
    .vertical-pannel .card .card-header+div>div:last-child, .vertical-pannel .card .card-header+div>p:last-child, .vertical-pannel .card .card-header+div>button:last-child, .vertical-pannel .card .card-header1+div>div:last-child, .vertical-pannel .card .card-header1+div>p:last-child, .vertical-pannel .card .card-header1+div>button:last-child {
        margin-bottom: 20px;
    }
    .vertical-pannel .tab-content .tab-pane {
        display: block !important;
        opacity: 1;
        margin-bottom: 16px;
    }
    .vertical-pannel .card .card-header+div>div:first-child, .vertical-pannel .card .card-header+div>p:first-child, .vertical-pannel .card .card-header+div>button:first-child, .vertical-pannel .card .card-header1+div>div:first-child, .vertical-pannel .card .card-header1+div>p:first-child, .vertical-pannel .card .card-header1+div>button:first-child {
        margin-top: 20px;
    }
    /* .vertical-pannel .card .card-header a {
        margin-left: -30px;
        display: block;
        color: #B71B1B;
        width: calc(100% + 50px);
        padding: 14px 30px;
        text-decoration: none;
    } */
    .vertical-pannel .card .card-header+div, .vertical-pannel .card .card-header1+div {
        padding: 20px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .vertical-pannel .card .card-header, .vertical-pannel .card .card-header1 {
        background: transparent;
        border: 1px solid #B71B1B;
    }
    .vertical-pannel-wrap .right-wrap-panel {
        width: 100%;
    }
    .vertical-pannel-wrap .left-wrap-panel {
        width: 0;
    }
    .vertical-pannel .card .card-header a, .vertical-pannel .card .card-header1 a {
        display: block;
        color: #B71B1B;
        text-decoration: none;
    }
    .vertical-pannel-wrap .right-wrap-panel {
        /* width: calc(100% - 175px); */
        border: 0;
        background: transparent;
        padding: 0;
        border-radius: 4px;
        min-height: 100%;
    }
    .card .card-header1 {
        border-radius: 4px 4px 0 0;
        background-color: #B71B1B;
        /* box-shadow: 0 0 7px 3px rgba(227,57,69,0.27); */
        height: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: -webkit-flex;
        -webkit-align-items: center;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        padding-left: 30px;
    }
    .btn {
        font-size: 14px;
        min-width: 130px;
        padding: 6px 10px;
    }
    .content-box table, .content-box tbody, .content-box tr, .content-box td, .content-box th {
        display: block;
        width: 100%;
    }
    .content-box table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
        max-height: 60px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
    }
    .content-box.global-sm-table table tbody tr td table tbody tr, .content-box table thead tr td table thead tr {
        max-height: 30px !important;
    }
    .content-box.global-sm-table table tbody tr td table tbody tr.accordion-opened, .global-sm-table.content-box table thead tr td table thead tr {
        max-height: 100% !important;
    }
    .global-sm-table .icon-accordion {
        top: -2px;
    }
    .global-sm-table #thid>tbody>tr:first-child>th:first-child {
        line-height: 24px;
    }
    .content-box tr:first-child td:first-child {
        color: #6e6e6e;
    }
    .content-box td h3 {
        display: block;
    }
    .team-card-inn-body>ul>li {
        padding-left: 62px;
    }
    .team-card-inn-body {
        padding: 15px;
    }
    .team-card-inn-body .step-head {
        margin-bottom: 44px;
    }
    .mobile-mr-btn-20 {
        margin-bottom: 20px;
    }
    .mob-dis-none {
        display: none;
    }
    .dropdown.filter-dropdown.mob-dis-none {
        display: none;
    }
    .user-address-fld:before {
        content: "";
        width: calc(100% + 30px);
        height: 1px;
        /* background: #979797; */
        position: absolute;
        border-bottom: 2px dashed #979797;
        top: -18px;
        left: -15px;
    }
    .user-name-add-inn .user-address-fld {
        width: 100%;
    }
    .user-name-add-inn .user-name-fld {
        width: 100%;
        margin-bottom: 32px;
    }
    .user-name-add-inn {
        display: block;
    }
    .chat-wrap .chat {
        display: none;
    }
    .people-list {
        width: 100%;
        margin-right: 0;
        min-width: auto;
    }
    .chat-details .people-list {
        display: none;
    }
    .chat-details.chat-wrap .chat {
        display: block;
    }
    .chat-wrap .chat {
        height: 100%;
        min-height: auto;
    }
    .chat .chat-history-wrap {
        height: 100%;
    }
    .filter-row .dropdown.filter-dropdown button {
        display: none;
    }
    .income-chart-rt .filter-row .dropdown.filter-dropdown button{
        display: block;
    }
    .timecard-table-scroll.timecard-table-scroll-mng {
        margin-left: 0;
        padding-bottom: 50px;
        width: 100%;
    }
    .graph-area {
        padding: 0 15px;
    }
    .table-in-mob {
        min-width: 700px;
    }
    .responsive-mob .filter-row .dropdown.filter-dropdown button {
        display: block;
    }
}

@media (max-width: 480px) {
    .people-list {
        width: 100%;
        min-width: 100%;       
    }
    .force-overflow-edit-team .form-group.dis-flex.f-dir-column.mr-top-24 {
        margin-top: 62px;
    }
    .report-container .report-body {
        padding: 20px 16px 20px 0;
    }
    .responsive-mob .top-search-field>.search-container {
        position: relative;
    }
    .responsive-mob .top-search-field {
        display: block !important;
    }
    .responsive-mob .search-container button {
        width: 40px;
        position: absolute;
        right: 0;
    }
    .responsive-mob .search-container input {
        min-width: 100%;
    }
    .report-container .report-body .report-list ul li {
        width: 28%;
    }
    .monitor-radio label {
        margin-right: 32px;
    }
    .main-container.container-dash-bg:before {
        height: 130px;
    }
    .total-part-labor .totap-rice {
        padding: 5px 2px;
    }
    .total-part-labor {
        border: none;
    }
    .btn-rt-align button {
        width: 100%;
        margin-left: 0;
    }
    .btn-rt-align button:first-child {
        margin-bottom: 16px;
    }
    .time-card-section.mobile-week-date .week-date {
        position: relative;
    }
    .time-card-section.mobile-week-date {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .mobile-week-date p.time-card.sprite-icon-before:before {
        background-position: -60px -483px;
        position: absolute;
        width: 16px;
        height: 16px;
        right: auto;
        top: 3px;
        left: 80px;
    }
    .date-show-feild {
        width: 100%;
        height: auto;
    }
    .subscription-lists .list-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .subscription-lists .rt-rate {
        border-left: none;
    }
    .mark-lists {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
    }
    .mark-lists>label {
        margin-bottom: 12px;
    }
    .sm-search-fld.search-container input {
        min-width: 140px;
        height: 30px;
        width: 140px;
    }
    .search-container.sm-search-fld button {
        width: 42px;
    }
    .search-container.sm-search-fld button .search-icon {
        left: 12px;
    }
    .filter-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        display: -wevkit-flex;
        -webkit-flex-wrap: nowrap;
        /* overflow: scroll; */
    }
    .step-checks ul li {
        width: 100%;
        margin-bottom: 30px;
    }
}

/* css for job-link page*/

.dropdown-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.emplyee-list-rt.job-list {
    width: 315px;
}

.mw-150 {
    min-width: 150px !important;
}

.rectangle-copy-20 {
    height: 30px;
    width: 131px;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.dropdown-container>.dropdown.custom-drop-option>button {
    min-width: 120px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-right: 35px;
    padding-left: 10px;
    min-height: 35px;
    max-height: 35px;
}

p.job-list-icon.sprite-icon-before1 {
    padding-left: 40px;
}

p.job-list-icon.sprite-icon-before1:before {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    background-position: -14px -514px;
}

.hide {
    display: none;
}

.lower-arrow:before {
    background-position: -14px -400px !important;
}

/*CSS for job link page ends here*/

/* view report table css listing*/

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(2) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(3) {
    width: 15%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(4) {
    width: 18%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 15%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 18%;
}

/* report table listing css */

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:first-child {
    width: 15%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(2) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(3) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(4) {
    width: 15%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(5) {
    width: 13%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(6) {
    width: 9%;
}

.report-listing-table.global-table.customer-table .table tbody tr td table tbody tr td:nth-child(7) {
    width: 12%;
}

.report-listing-table.global-table.customer-table .table thead tr th:first-child {
    width: 8%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(2) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(3) {
    width: 16%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(4) {
    width: 15%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(5) {
    width: 22%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(6) {
    width: 10%;
}

.report-listing-table.global-table.customer-table .table thead tr th:nth-child(7) {
    width: 12%;
}

.breadcrumes ul li {
    color: #535252;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
}

.upload-ios {
    -webkit-filter: invert(35%) sepia(50%) saturate(5718%) hue-rotate(200deg) brightness(102%) contrast(107%);
            filter: invert(35%) sepia(50%) saturate(5718%) hue-rotate(200deg) brightness(102%) contrast(107%);
}